import { SET_INITIAL_USER, REMOVE_USER_DATA } from './constant';
import { api } from '../../service';
import { remove, set } from '../../utils/storage';
import { MAIN_TOKEN_KEY } from '../../constants/app';

/**
 * Login with email and password.
 *
 * @param {string} email
 * @param {string} password
 * @returns
 */
export const login = (userInfo) => async (dispatch) => {
  try {
    const { data } = await api.post('/backoffice/login', userInfo);

    if (data) {
      // Set the token into the localStorage.
      set(MAIN_TOKEN_KEY, data.token);

      // Dispatch User Info.
      dispatch({ type: SET_INITIAL_USER, payload: data });
    }
    return data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Logout from the dashboard and redirect to the login page.
 *
 * @param {Object} history
 * @param {Object} location
 * @returns
 */
export const logout = (navigate, location) => async (dispatch) => {
  console.log("enter logout")
  const { data } = await api.post(`/backoffice/logout`);

  if (data) {
    // remove token
    remove(MAIN_TOKEN_KEY);

    // Dispatch User Info.
    if (navigate && location) {
      navigate('/login', { replace: true });
    }
    // remove user data
    dispatch({ type: REMOVE_USER_DATA });
  }
};
