import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { useToast } from 'src/contexts/toast';
import { getMakesList } from '../../redux/vehicles-make/action';
import { getModelByID, updateModelByID } from '../../redux/vehicles-model/action';

const EditModel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { showToast } = useToast();
  const [makes, setMakes] = useState();
  const [modelInfo, setModelInfo] = useState();
  const modelID = location.state;

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await dispatch(getMakesList());
  //     setMakes(data);
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data1 = await dispatch(getMakesList());
      setMakes(data1);
      const data2 = await dispatch(getModelByID(modelID));
      setModelInfo(data2[0]);
    };
    fetchData();
  }, [modelID]);

  const goBack = () => {
    navigate('/app/models');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Edit Model" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    make_id: modelInfo ? modelInfo.make_id : '',
                    model: modelInfo ? modelInfo.model : ''
                  }}
                  validationSchema={Yup.object().shape({
                    make_id: Yup.string().max(255).required('Make is required'),
                    model: Yup.string().max(255).required('Model is required')
                  })}
                  onSubmit={async (values) => {
                    try {
                      await dispatch(updateModelByID(values, modelID));
                      showToast("Model is updated successfully!");
                      navigate('/app/models', { replace: true });
                    } catch (error) {
                      showToast(error.message, "warning");
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        label="Select Make"
                        name="make_id"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.make_id}
                        variant="outlined"
                      >
                        {makes && makes.length !== 0 && makes.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.make}
                          </option>
                        ))}
                      </TextField>
                      <TextField
                        error={Boolean(touched.model && errors.model)}
                        fullWidth
                        helperText={touched.model && errors.model}
                        label="Enter Model"
                        margin="normal"
                        name="model"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.model}
                        variant="outlined"
                      />
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              type="submit"
                              variant="contained"
                            >
                              UPDATE
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default EditModel;
