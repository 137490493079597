import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  FormHelperText,
  TextField,
  Divider,
  CardContent,
  Typography,
} from '@material-ui/core';

// eslint-disable-next-line react/prop-types
const GeneralSettings = ({ handleGeneralSetting, settings }) => {
  const generalSetting = settings;

  return (
    <Card>
      <CardHeader title="Translations" />
      <Divider />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            app_name: generalSetting ? generalSetting[1].pt : '',
            company_email: generalSetting ? generalSetting[1].en : '',
            company_address: generalSetting ? generalSetting[2].pt : '',
            timezone_offset: generalSetting ? generalSetting[2].en : '',
            default_password: generalSetting ? generalSetting[3].pt : '',
            number_driver_cancel_per_day: generalSetting
              ? generalSetting[3].en
              : '',
            special_washing_needs_message_1_pt: generalSetting
              ? generalSetting[4].pt
              : '',
            special_washing_needs_message_1_en: generalSetting
              ? generalSetting[4].en
              : '',
            special_washing_needs_message_2_pt: generalSetting
              ? generalSetting[0].pt
              : '',
            special_washing_needs_message_2_en: generalSetting
              ? generalSetting[0].en
              : '',
          }}
          onSubmit={(values) => {
            const generalSettings = {
              parking_address_pt: values.app_name,
              parking_address_en: values.company_email,
              welcome_msg_1_pt: values.company_address,
              welcome_msg_1_en: values.timezone_offset,
              welcome_msg_2_pt: values.default_password,
              welcome_msg_2_en: values.number_driver_cancel_per_day,
              services_info_msg_1_pt: values.special_washing_needs_message_1_pt,
              services_info_msg_1_en: values.special_washing_needs_message_1_en,
              services_info_msg_2_pt: values.special_washing_needs_message_2_pt,
              services_info_msg_2_en: values.special_washing_needs_message_2_en,
            };

            handleGeneralSetting(generalSettings);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={6} md={6} sm={6}>
                  <Typography>Português</Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography>English</Typography>
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(touched.app_name && errors.app_name)}
                    fullWidth
                    helperText={touched.app_name && errors.app_name}
                    label="Parking Address"
                    name="app_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.app_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.company_email && errors.company_email
                    )}
                    fullWidth
                    helperText={touched.company_email && errors.company_email}
                    label="Parking Address"
                    name="company_email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12} />
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.company_address && errors.company_address
                    )}
                    fullWidth
                    helperText={
                      touched.company_address && errors.company_address
                    }
                    label="Welcome Message 1"
                    name="company_address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.timezone_offset && errors.timezone_offset
                    )}
                    fullWidth
                    helperText={
                      touched.timezone_offset && errors.timezone_offset
                    }
                    label="Welcome Message 1"
                    name="timezone_offset"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.timezone_offset}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.default_password && errors.default_password
                    )}
                    fullWidth
                    helperText={
                      touched.default_password && errors.default_password
                    }
                    label="Welcome Message 2"
                    name="default_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.default_password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.number_driver_cancel_per_day &&
                        errors.number_driver_cancel_per_day
                    )}
                    fullWidth
                    helperText={
                      touched.number_driver_cancel_per_day &&
                      errors.number_driver_cancel_per_day
                    }
                    label="Welcome Message 2"
                    name="number_driver_cancel_per_day"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.number_driver_cancel_per_day}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12} />
                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.special_washing_needs_message_1_pt &&
                        errors.special_washing_needs_message_1_pt
                    )}
                    fullWidth
                    helperText={
                      touched.special_washing_needs_message_1_pt &&
                      errors.special_washing_needs_message_1_pt
                    }
                    label="Special Washing Needs Message 1"
                    name="special_washing_needs_message_1_pt"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.special_washing_needs_message_1_pt}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.special_washing_needs_message_1_en &&
                        errors.special_washing_needs_message_1_en
                    )}
                    fullWidth
                    helperText={
                      touched.special_washing_needs_message_1_en &&
                      errors.special_washing_needs_message_1_en
                    }
                    label="Special Washing Needs Message 1"
                    name="special_washing_needs_message_1_en"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.special_washing_needs_message_1_en}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.special_washing_needs_message_2_pt &&
                        errors.special_washing_needs_message_2_pt
                    )}
                    fullWidth
                    helperText={
                      touched.special_washing_needs_message_2_pt &&
                      errors.special_washing_needs_message_2_pt
                    }
                    label="Special Washing Needs Message 2"
                    name="special_washing_needs_message_2_pt"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.special_washing_needs_message_2_pt}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} md={6} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.special_washing_needs_message_2_en &&
                        errors.special_washing_needs_message_2_en
                    )}
                    fullWidth
                    helperText={
                      touched.special_washing_needs_message_2_en &&
                      errors.special_washing_needs_message_2_en
                    }
                    label="Special Washing Needs Message 2"
                    name="special_washing_needs_message_2_en"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.special_washing_needs_message_2_en}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Box paddingY={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default GeneralSettings;
