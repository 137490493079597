import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getClientByID, updateClientByID } from 'src/redux/clients/action';
import { useToast } from 'src/contexts/toast';
import { COUNTRIES } from 'src/constants';
import { getVehiclesList } from 'src/redux/vehicles/action';
import VehiclesListResults from 'src/components/vehicles/VehiclesListResults';

const EditClient = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [client, setClient] = useState();
  const [clientVehicles, setClientVehicles] = useState([]);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(getClientByID(params.clientId)),
      dispatch(getVehiclesList()),
    ]).then(([dataClient, dataClientVehicles]) => {
      setClient(dataClient[0]);
      setClientVehicles(
        dataClientVehicles.filter(
          (item) => item.client_id === Number(params.clientId)
        )
      );
      setLoading(false);
    });
  }, [params.clientId]);

  const goBack = () => {
    navigate('/app/clients');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Edit Client" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {loading && <CircularProgress />}
                {!loading && (
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          email: client ? client.email : '',
                          firstname: client ? client.firstname : '',
                          lastname: client ? client.lastname : '',
                          prefix: client ? client.prefix : '',
                          phonenumber: client ? client.phonenumber : '',
                          last_four: client ? client.last_four : '',
                          card_id: client ? client.card_id : '',
                        }}
                        validationSchema={Yup.object().shape({
                          firstname: Yup.string()
                            .max(255)
                            .required('First name is required'),
                          lastname: Yup.string()
                            .max(255)
                            .required('Last name is required'),
                          email: Yup.string()
                            .email('Must be a valid email')
                            .max(255)
                            .required('Email is required'),
                          phonenumber: Yup.string().required(
                            'Phone number is required'
                          ),
                          prefix: Yup.string().required(
                            'Last name is required'
                          ),
                        })}
                        onSubmit={async (values) => {
                          try {
                            const data = values;
                            data.phonenumber = values.phonenumber.toString();
                            await dispatch(
                              updateClientByID(values, params.clientId)
                            );
                            showToast('Client is updated successfully!');
                            navigate('/app/clients', { replace: true });
                          } catch (error) {
                            showToast(error.message, 'warning');
                          }
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              error={Boolean(
                                touched.firstname && errors.firstname
                              )}
                              fullWidth
                              helperText={touched.firstname && errors.firstname}
                              label="First name"
                              margin="normal"
                              name="firstname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstname}
                            />
                            <TextField
                              error={Boolean(
                                touched.lastname && errors.lastname
                              )}
                              fullWidth
                              helperText={touched.lastname && errors.lastname}
                              label="Last name"
                              margin="normal"
                              name="lastname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastname}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email}
                              label="Email Address"
                              margin="normal"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              value={values.email}
                              variant="outlined"
                            />
                            {values.last_four === '' ? (
                              <TextField
                                error={Boolean(
                                  touched.lastname && errors.lastname
                                )}
                                fullWidth
                                helperText={touched.lastname && errors.lastname}
                                label="Default Payment Method"
                                margin="normal"
                                name="card_id"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.card_id}
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            ) : (
                              <TextField
                                error={Boolean(
                                  touched.lastname && errors.lastname
                                )}
                                fullWidth
                                helperText={touched.lastname && errors.lastname}
                                label="Default Payment Method"
                                margin="normal"
                                name="last_four"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={`XXXX-XXXX-XXXX-${values.last_four}`}
                                variant="outlined"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                            <Grid
                              container
                              spacing={2}
                              justifyContent="flex-start"
                            >
                              <Grid item xs={12} sm={5}>
                                <TextField
                                  fullWidth
                                  label="Select country code"
                                  name="prefix"
                                  onChange={handleChange}
                                  required
                                  select
                                  margin="normal"
                                  SelectProps={{ native: true }}
                                  value={values.prefix}
                                  variant="outlined"
                                >
                                  {COUNTRIES &&
                                    COUNTRIES.map((option) => (
                                      <option
                                        key={option.value}
                                        value={`+${option.phone}`}
                                      >
                                        {option.label} ({option.code}) +
                                        {option.phone}
                                      </option>
                                    ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={7}>
                                <TextField
                                  error={Boolean(
                                    touched.phonenumber && errors.phonenumber
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.phonenumber && errors.phonenumber
                                  }
                                  label="Phone number"
                                  margin="normal"
                                  name="phonenumber"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="number"
                                  value={values.phonenumber}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <VehiclesListResults
                                vehicles={clientVehicles}
                                loading={loading}
                                actionHandler={false}
                                isPagination={false}
                              />
                            </Grid>
                            {Boolean(touched.policy && errors.policy) && (
                              <FormHelperText error>
                                {errors.policy}
                              </FormHelperText>
                            )}
                            <Box paddingY={3}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                  >
                                    UPDATE
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    onClick={goBack}
                                    variant="contained"
                                  >
                                    GO BACK
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EditClient;
