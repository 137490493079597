import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { api } from 'src/service';

const PaymentDetails = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [payment, setPayment] = useState();
  const orderID = location.state;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api.get(`/payment_order/${orderID}`).then((res) => {
          setPayment(res.data.data[0]);
        });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const goBack = () => {
    navigate(`/app/payments/washer/${payment.washer_id}`);
  };

  const goToInvoice = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Payment Details" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            {loading && <CircularProgress />}
            {!loading && (
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} sm={8}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      client_firstname: payment ? payment.client_firstname : '',
                      client_lastname: payment ? payment.client_lastname : '',
                      client_email: payment ? payment.client_email : '',
                      washer_firstname: payment ? payment.washer_firstname : '',
                      washer_lastname: payment ? payment.washer_lastname : '',
                      washer_email: payment ? payment.washer_email : '',
                      service_name: payment ? payment.service_name : '',
                      license_plate: payment ? payment.license_plate : '',
                      status_order: payment ? payment.status_order : '',
                      price: payment ? payment.price : '',
                      commission: payment ? payment.commission : '',
                      pay_method: payment ? payment.pay_method : '',
                      status: payment ? payment.status : '',
                      iban: payment ? payment.iban : '',
                      invoice_url: payment ? payment.invoice_url : '',
                      start_service_date: payment
                        ? payment.start_service_date
                        : '',
                      finish_service_date: payment
                        ? payment.finish_service_date
                        : '',
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid
                          mb={4}
                          container
                          spacing={2}
                          justifyContent="flex-start"
                        >
                          <Grid item xs={12} md={3} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.client_firstname &&
                                  errors.client_firstname
                              )}
                              fullWidth
                              helperText={
                                touched.client_firstname &&
                                errors.client_firstname
                              }
                              label="Client first name"
                              margin="normal"
                              name="client_firstname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.client_firstname}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.client_lastname &&
                                  errors.client_lastname
                              )}
                              fullWidth
                              helperText={
                                touched.client_lastname &&
                                errors.client_lastname
                              }
                              label="Client last name"
                              margin="normal"
                              name="client_lastname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.client_lastname}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.client_email && errors.client_email
                              )}
                              fullWidth
                              helperText={
                                touched.client_email && errors.client_email
                              }
                              label="Client email"
                              margin="normal"
                              name="client_email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              value={values.client_email}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          mb={4}
                          container
                          spacing={2}
                          justifyContent="flex-start"
                        >
                          <Grid item xs={12} md={3} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.washer_firstname &&
                                  errors.washer_firstname
                              )}
                              fullWidth
                              helperText={
                                touched.washer_firstname &&
                                errors.washer_firstname
                              }
                              label="Washer first name"
                              margin="normal"
                              name="washer_firstname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.washer_firstname}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.washer_lastname &&
                                  errors.washer_lastname
                              )}
                              fullWidth
                              helperText={
                                touched.washer_lastname &&
                                errors.washer_lastname
                              }
                              label="Washer last name"
                              margin="normal"
                              name="washer_lastname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.washer_lastname}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={6}>
                            <TextField
                              error={Boolean(
                                touched.washer_email && errors.washer_email
                              )}
                              fullWidth
                              helperText={
                                touched.washer_email && errors.washer_email
                              }
                              label="Washer email"
                              margin="normal"
                              name="washer_email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              value={values.washer_email}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} sm={6}>
                            <TextField
                              error={Boolean(touched.iban && errors.iban)}
                              fullWidth
                              helperText={touched.iban && errors.iban}
                              label="Washer IBAN"
                              margin="normal"
                              name="iban"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.iban}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          mb={4}
                          container
                          spacing={2}
                          justifyContent="flex-start"
                        >
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.service_name && errors.service_name
                              )}
                              fullWidth
                              helperText={
                                touched.service_name && errors.service_name
                              }
                              label="Service"
                              margin="normal"
                              name="service_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.service_name}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.license_plate && errors.license_plate
                              )}
                              fullWidth
                              helperText={
                                touched.license_plate && errors.license_plate
                              }
                              label="License plate"
                              margin="normal"
                              name="license_plate"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.license_plate}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.status_order && errors.status_order
                              )}
                              fullWidth
                              helperText={
                                touched.status_order && errors.status_order
                              }
                              label="Order status"
                              margin="normal"
                              name="status_order"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.status_order}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.start_service_date &&
                                  errors.start_service_date
                              )}
                              fullWidth
                              helperText={
                                touched.start_service_date &&
                                errors.start_service_date
                              }
                              label="Start service date"
                              margin="normal"
                              name="start_service_date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.start_service_date}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.finish_service_date &&
                                  errors.finish_service_date
                              )}
                              fullWidth
                              helperText={
                                touched.finish_service_date &&
                                errors.finish_service_date
                              }
                              label="Finish service date"
                              margin="normal"
                              name="finish_service_date"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.finish_service_date}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          mb={4}
                          container
                          spacing={2}
                          justifyContent="flex-start"
                        >
                          <Grid item xs={12} md={3} sm={12}>
                            <TextField
                              error={Boolean(touched.price && errors.price)}
                              fullWidth
                              helperText={touched.price && errors.price}
                              label="Price"
                              margin="normal"
                              name="price"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.price}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.commission && errors.commission
                              )}
                              fullWidth
                              helperText={
                                touched.commission && errors.commission
                              }
                              label="Commission"
                              margin="normal"
                              name="commission"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.commission}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3} sm={12}>
                            <TextField
                              error={Boolean(
                                touched.pay_method && errors.pay_method
                              )}
                              fullWidth
                              helperText={
                                touched.pay_method && errors.pay_method
                              }
                              label="pay_method"
                              margin="normal"
                              name="pay_method"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.pay_method}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={3} sm={12}>
                            <TextField
                              error={Boolean(touched.status && errors.status)}
                              fullWidth
                              helperText={touched.status && errors.status}
                              label="Payment status"
                              margin="normal"
                              name="status"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.status}
                              variant="outlined"
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          mb={4}
                          container
                          spacing={2}
                          justifyContent="flex-start"
                        >
                          <Grid item xs={12} md={12} sm={12}>
                            <Button
                              color="primary"
                              size="large"
                              fullWidth
                              variant="contained"
                              onClick={() => goToInvoice(values.invoice_url)}
                            >
                              INVOICE
                            </Button>
                          </Grid>
                        </Grid>

                        {Boolean(touched.policy && errors.policy) && (
                          <FormHelperText error>{errors.policy}</FormHelperText>
                        )}
                        <Box paddingY={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={6} md={6}>
                              <Button
                                color="primary"
                                disabled={isSubmitting}
                                size="large"
                                fullWidth
                                onClick={goBack}
                                variant="contained"
                              >
                                GO BACK
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default PaymentDetails;
