import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Admins from 'src/containers/admin/Admins';
import Services from 'src/containers/services/Services';
import ServiceSettings from 'src/containers/services/ServiceSettings';
import Clients from 'src/containers/clients/Clients';
import ClientFailedPayments from 'src/containers/clients/ClientFailedPayments';
import Jobs from 'src/containers/jobs/Jobs';
import Reviews from 'src/containers/reviews/Reviews';
import Cancelations from 'src/containers/cancelations/Cancelations';
import Complaints from 'src/containers/complaints/Complaints';
import ReviewComplaint from 'src/containers/complaints/ReviewComplaint';
import Payments from 'src/containers/payments/Payments';
import PaymentDetails from 'src/containers/payments/PaymentDetails';
import PaymentsByWasher from 'src/containers/payments/PaymentsByWasher';
import ExtraPayment from 'src/containers/payments/ExtraPayments';
import Settlement from 'src/containers/settlement/Settlement';
import SettlementDetails from 'src/containers/settlement/SettlementDetails';
import SettlementAddTransfer from 'src/containers/settlement/SettlementAddTransfer';
import Utility from 'src/containers/utility/Utility';
import WasherList from 'src/containers/washers/WasherList';
import Login from 'src/containers/Login';
import NotFound from 'src/containers/NotFound';
import Register from 'src/containers/Register';
import Settings from 'src/containers/Settings';
import Translations from 'src/containers/Translations';
import Vehicles from './containers/vehicles/Vehicles';
import VehicleTypes from './containers/vehicle-types/VehicleTypes';
import AddAdminUser from './containers/admin/AddAdminUser';
import EditAdminUser from './containers/admin/EditAdminUser';
import AddService from './containers/services/AddService';
import EditService from './containers/services/EditService';
import AddClient from './containers/clients/AddClient';
import EditClient from './containers/clients/EditClient';
import AddWasher from './containers/washers/AddWasher';
import EditWasher from './containers/washers/EditWasher';
import AddJob from './containers/jobs/AddJob';
import EditJob from './containers/jobs/EditJob';
import AddVehicle from './containers/vehicles/AddVehicle';
import EditVehicle from './containers/vehicles/EditVehicle';
import AddVehicleType from './containers/vehicle-types/AddVehicleType';
import EditVehicleType from './containers/vehicle-types/EditVehicleType';
import Makes from './containers/makes/Makes';
import AddMake from './containers/makes/AddMake';
import EditMake from './containers/makes/EditMake';
import Models from './containers/models/Models';
import AddModel from './containers/models/AddModel';
import EditModel from './containers/models/EditModel';
import Categories from './containers/categories/Categories';
import AddCategory from './containers/categories/AddCategory';
import EditCategory from './containers/categories/EditCategory';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      {
        path: 'admins',
        children: [
          { path: 'add', element: <AddAdminUser /> },
          { path: 'edit/:userId', element: <EditAdminUser /> },
          {
            path: '',
            element: <Admins />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'services',
        children: [
          { path: 'add', element: <AddService /> },
          { path: 'edit/:serviceId', element: <EditService /> },
          {
            path: '',
            element: <Services />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'services_settings',
        children: [
          {
            path: '',
            element: <ServiceSettings />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'clients',
        children: [
          { path: 'add', element: <AddClient /> },
          { path: 'edit/:clientId', element: <EditClient path=":clientId" /> },
          {
            path: '',
            element: <Clients />,
          },
          {
            path: 'failed_payments/:clientId',
            element: <ClientFailedPayments path=":clientId" />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'washers',
        children: [
          { path: 'add', element: <AddWasher /> },
          { path: 'edit/:washerId', element: <EditWasher /> },
          {
            path: '',
            element: <WasherList />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'jobs',
        children: [
          { path: 'add', element: <AddJob /> },
          { path: 'edit/:jobId', element: <EditJob /> },
          {
            path: '',
            element: <Jobs />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'payments',
        children: [
          {
            path: '',
            element: <Payments />,
          },
          {
            path: 'washer/:washerId',
            element: <PaymentsByWasher />,
          },
          {
            path: 'details/:orderId',
            element: <PaymentDetails />,
          },
          {
            path: 'extra/:washerId',
            element: <ExtraPayment />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'reviews',
        children: [
          {
            path: '',
            element: <Reviews />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'cancelations',
        children: [
          {
            path: '',
            element: <Cancelations />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'complaints',
        children: [
          {
            path: '',
            element: <Complaints />,
          },
          { path: 'review/:jobId', element: <ReviewComplaint /> },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'vehicle-types',
        children: [
          { path: 'add', element: <AddVehicleType /> },
          { path: 'edit/:serviceId', element: <EditVehicleType /> },
          {
            path: '',
            element: <VehicleTypes />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'vehicles',
        children: [
          { path: 'add', element: <AddVehicle /> },
          { path: 'edit/:vehicleId', element: <EditVehicle /> },
          {
            path: '',
            element: <Vehicles />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'makes',
        children: [
          { path: 'add', element: <AddMake /> },
          { path: 'edit/:makeId', element: <EditMake /> },
          {
            path: '',
            element: <Makes />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'models',
        children: [
          { path: 'add', element: <AddModel /> },
          { path: 'edit/:modelId', element: <EditModel /> },
          {
            path: '',
            element: <Models />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'categories',
        children: [
          { path: 'add', element: <AddCategory /> },
          { path: 'edit/:categoryId', element: <EditCategory /> },
          {
            path: '',
            element: <Categories />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      {
        path: 'settlement',
        children: [
          { path: '', element: <Settlement /> },
          { path: 'washer/:washerId', element: <SettlementDetails /> },
          {
            path: 'washer_transfer/:washerId',
            element: <SettlementAddTransfer />,
          },
          { path: '*', element: <Navigate to="/404" /> },
        ],
      },
      { path: 'utility', element: <Utility /> },
      { path: 'translations', element: <Translations /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '', element: <Navigate to="/app/admins" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
