import React from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Button,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const SettlementDetailsListToolBar = ({ handleSearchStr, id }) => {
  const navigate = useNavigate();

  const goToAdd = (washerId) => {
    navigate(`/app/settlement/washer_transfer/${washerId}`);
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={10} sm={10}>
                <Box sx={{ maxWidth: 500 }}>
                  <TextField
                    fullWidth
                    onChange={(e) => handleSearchStr(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search payment"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sm={2}
                sx={{ alignSelf: 'center', paddingRight: '3rem' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => goToAdd(id)}
                  >
                    TRANSFER TO WASHER
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default SettlementDetailsListToolBar;
