/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import IBAN from 'iban';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getDocsByWasherID,
  getWasherByID,
  updateWasherByID,
} from 'src/redux/washers/action';
import { useToast } from 'src/contexts/toast';
import { COUNTRIES } from 'src/constants';
import {
  getServiceList,
  getServicesByWasherID,
  updateServiceByWasherID,
} from 'src/redux/services/action';
import PropTypes from 'prop-types';

const CLIENT_STATUS = [
  {
    value: 'new',
    label: 'New',
  },
  {
    value: 'processing',
    label: 'Processing',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
];

const EditWasher = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [washer, setWasher] = useState();
  const [services, setServices] = useState();
  const [washerDocs, setWasherDocs] = useState();
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(getWasherByID(params.washerId)),
      dispatch(getServiceList()),
      dispatch(getServicesByWasherID(params.washerId)),
      dispatch(getDocsByWasherID(params.washerId)),
    ]).then(([dWasher, dServices, dSelectedServices, dDocs]) => {
      setWasher(dWasher[0]);
      setServices(dServices);
      setSelectedServices(dSelectedServices);
      setWasherDocs(dDocs);
      setLoading(false);
    });
  }, [params.washerId, setSelectedServices]);

  const goBack = () => {
    navigate('/app/washers');
  };

  const handleEditImage = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Edit Washer" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {loading && <CircularProgress />}
                {!loading && (
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          firstname: washer ? washer.firstname : '',
                          lastname: washer ? washer.lastname : '',
                          email: washer ? washer.email : '',
                          address: washer ? washer.address : '',
                          operation_range: washer ? washer.operation_range : '',
                          prefix: washer ? washer.prefix : '',
                          nif: washer ? washer.nif : '',
                          cc: washer ? washer.cc : '',
                          iban: washer ? washer.iban : '',
                          commission: washer ? washer.commission : '',
                          phonenumber: washer ? washer.phonenumber : '',
                          status: washer ? washer.status : '',
                        }}
                        validationSchema={Yup.object().shape({
                          firstname: Yup.string()
                            .max(255)
                            .required('First name is required'),
                          lastname: Yup.string()
                            .max(255)
                            .required('Last name is required'),
                          email: Yup.string()
                            .email('Must be a valid email')
                            .max(255)
                            .required('Email is required'),
                          address: Yup.string()
                            .max(255)
                            .required('Address is required'),
                          operation_range: Yup.number().required(
                            'Range is required'
                          ),
                          prefix: Yup.string().required(
                            'Country code is required'
                          ),
                          nif: Yup.string()
                            .max(255)
                            .required('NIF is required'),
                          cc: Yup.string().max(255).required('CC is required'),
                          iban: Yup.string()
                            .max(255)
                            .required('IBAN is required')
                            .test(
                              'ibanFormat',
                              'Invalid format',
                              (value) => value && IBAN.isValid(value)
                            ),
                          commission: Yup.number().required(
                            'Commission is required'
                          ),
                          phonenumber: Yup.string().required(
                            'Phone number is required'
                          ),
                        })}
                        onSubmit={async (values) => {
                          try {
                            const data = {
                              address: values.address,
                              cc: values.cc,
                              commission: values.commission,
                              email: values.email,
                              firstname: values.firstname,
                              iban: values.iban,
                              lastname: values.lastname,
                              nif: values.nif,
                              phonenumber: values.phonenumber.toString(),
                              prefix: values.prefix,
                              status: values.status,
                            };
                            await dispatch(
                              updateWasherByID(data, params.washerId)
                            );
                            await dispatch(
                              updateServiceByWasherID(
                                selectedServices,
                                params.washerId,
                                values.operation_range
                              )
                            );
                            showToast('Washer is updated successfully!');
                            navigate('/app/washers', { replace: true });
                          } catch (error) {
                            showToast(error.message, 'warning');
                          }
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="flex-start"
                            >
                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.firstname && errors.firstname
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.firstname && errors.firstname
                                  }
                                  label="First name"
                                  margin="normal"
                                  name="firstname"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.firstname}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.lastname && errors.lastname
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.lastname && errors.lastname
                                  }
                                  label="Last name"
                                  margin="normal"
                                  name="lastname"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.lastname}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(touched.email && errors.email)}
                                  fullWidth
                                  helperText={touched.email && errors.email}
                                  label="Email Address"
                                  margin="normal"
                                  name="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="email"
                                  value={values.email}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.address && errors.address
                                  )}
                                  fullWidth
                                  helperText={touched.address && errors.address}
                                  label="Address"
                                  margin="normal"
                                  name="address"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.address}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.operation_range &&
                                      errors.operation_range
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.operation_range &&
                                    errors.operation_range
                                  }
                                  label="Enter operation range"
                                  margin="normal"
                                  name="operation_range"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="number"
                                  value={values.operation_range}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(touched.nif && errors.nif)}
                                  fullWidth
                                  helperText={touched.nif && errors.nif}
                                  label="nif"
                                  margin="normal"
                                  name="nif"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.nif}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(touched.cc && errors.cc)}
                                  fullWidth
                                  helperText={touched.cc && errors.cc}
                                  label="CC"
                                  margin="normal"
                                  name="cc"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.cc}
                                  variant="outlined"
                                />
                              </Grid>

                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(touched.iban && errors.iban)}
                                  fullWidth
                                  helperText={touched.iban && errors.iban}
                                  label="iban"
                                  margin="normal"
                                  name="iban"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.iban}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.commission && errors.commission
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.commission && errors.commission
                                  }
                                  label="Enter commission"
                                  margin="normal"
                                  name="commission"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                  }}
                                  type="number"
                                  value={values.commission}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                <TextField
                                  fullWidth
                                  label="Status"
                                  name="status"
                                  margin="normal"
                                  onChange={handleChange}
                                  required
                                  select
                                  SelectProps={{ native: true }}
                                  value={values.status}
                                  variant="outlined"
                                >
                                  {CLIENT_STATUS.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} md={4} sm={12}>
                                <TextField
                                  fullWidth
                                  label="Select country code"
                                  name="prefix"
                                  onChange={handleChange}
                                  required
                                  select
                                  margin="normal"
                                  SelectProps={{ native: true }}
                                  value={values.prefix}
                                  variant="outlined"
                                >
                                  {COUNTRIES &&
                                    COUNTRIES.map((option) => (
                                      <option
                                        key={option.value}
                                        value={`+${option.phone}`}
                                      >
                                        {option.label} ({option.code}) +
                                        {option.phone}
                                      </option>
                                    ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} md={8} sm={12}>
                                <TextField
                                  error={Boolean(
                                    touched.phonenumber && errors.phonenumber
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.phonenumber && errors.phonenumber
                                  }
                                  label="Phone number"
                                  margin="normal"
                                  name="phonenumber"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="number"
                                  value={values.phonenumber}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} md={12} sm={12}>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  options={services || []}
                                  value={selectedServices}
                                  getOptionLabel={(option) =>
                                    option.service_name
                                  }
                                  onChange={(e, value) =>
                                    setSelectedServices(value)
                                  }
                                  filterSelectedOptions
                                  endAdornment={false}
                                  renderInput={(param) => (
                                    <TextField
                                      {...param}
                                      variant="outlined"
                                      label="Select services"
                                      placeholder="Favorites"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12} sm={12}>
                                <div className="washer_docs">
                                  <Typography
                                    style={{ padding: '20px 0 20px 0' }}
                                  >
                                    Washer Docs
                                  </Typography>
                                  <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">
                                            NO
                                          </TableCell>
                                          <TableCell align="center">
                                            Type of photo
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {washerDocs &&
                                          washerDocs.map((row, index) => (
                                            <TableRow key={row.name}>
                                              <TableCell align="center">
                                                {index + 1}
                                              </TableCell>
                                              <TableCell align="center">
                                                <p
                                                  style={{
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() =>
                                                    handleEditImage(
                                                      row.url_image
                                                    )
                                                  }
                                                >
                                                  {row.typeofphoto}
                                                </p>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                              </Grid>
                            </Grid>

                            {Boolean(touched.policy && errors.policy) && (
                              <FormHelperText error>
                                {errors.policy}
                              </FormHelperText>
                            )}
                            <Box paddingY={3}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                  >
                                    UPDATE
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    onClick={goBack}
                                    variant="contained"
                                  >
                                    GO BACK
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

EditWasher.propTypes = {
  loading: PropTypes.bool,
};

export default EditWasher;
