import { SET_JOBS_LIST } from './constant';

const DEFAULT_STATE = {
  list: null
};

const jobsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_JOBS_LIST:
      return { ...state, list: action.payload };
    default:
      return state;
  }
};

export default jobsReducer;
