import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DelteIcon from '@material-ui/icons/Delete';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'service_order',
    numeric: false,
    disablePadding: false,
    label: 'Order',
    sortable: true,
  },
  {
    id: 'acronym',
    numeric: false,
    disablePadding: false,
    label: 'Acronym',
    sortable: true,
  },
  {
    id: 'service_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
    sortable: true,
  },
  {
    id: 'price_class_1',
    numeric: false,
    disablePadding: false,
    label: 'Price Class 1',
    sortable: true,
  },
  {
    id: 'price_class_2',
    numeric: false,
    disablePadding: false,
    label: 'Price Class 2',
    sortable: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

const ServiceListResults = ({
  services,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToEdit = (id) => {
    navigate(`/app/services/edit/${id}`, { state: id });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                services &&
                stableSort(services, getComparator(order, orderBy)).map(
                  (user, index) => (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        {(pagerInfo.page - 1) * pagerInfo.limit + index + 1}
                      </TableCell>
                      <TableCell>{user.service_order}</TableCell>
                      <TableCell>{user.acronym}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {user.service_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{user.price_class_1}€</TableCell>
                      <TableCell>{user.price_class_2}€</TableCell>
                      <TableCell>{user.description}</TableCell>
                      <TableCell>{user.status}</TableCell>
                      <TableCell>
                        {user.status === 'active' && (
                          <>
                            <EditIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => goToEdit(user.id)}
                            />
                            <DelteIcon
                              style={{ cursor: 'pointer', color: 'indianred' }}
                              onClick={() => handleRow(user.id)}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!loading && !services && (
                <Typography className={classes.loader}>No Data</Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ServiceListResults.propTypes = {
  services: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default ServiceListResults;
