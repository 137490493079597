import { SET_VEHICLES_MAKE_LIST, SET_VEHICLES_MAKE_INFO } from './constant';
import { api } from '../../service';

/**
 * Get makes list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getMakesList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/vehicle_make');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_VEHICLES_MAKE_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get make by ID.
 *
 * @param {string} makeID
 * @returns
 */
export const getMakeByID = (makeID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/vehicle_make/${makeID}`);
    if (data) {
      dispatch({ type: SET_VEHICLES_MAKE_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update make by ID.
 *
 * @param {object} values
 * @param {string} makeID
 * @returns
 */
export const updateMakeByID = (values, makeID) => async () => {
  try {
    const { data } = await api.patch(`/vehicle_make/${makeID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add make.
 *
 * @param {object} values
 * @returns
 */
export const createMake = (values) => async () => {
  try {
    const { data } = await api.post('/vehicle_make', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove make by ID.
 *
 * @param {string} makeID
 * @returns
 */
export const removeMake = (makeID) => async () => {
  try {
    const { data } = await api.delete(`/vehicle_make/${makeID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
