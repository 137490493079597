import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useNavigate } from 'react-router-dom';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
    sortable: true,
  },
  {
    id: 'phonenumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
    sortable: true,
  },
  {
    id: 'balance',
    numeric: false,
    disablePadding: false,
    label: 'Balance',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Details',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ProviderSettlement = ({
  settlements,
  loading,
  pagerInfo,
  handlePagerInfo,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToEdit = (washerId) => {
    navigate(`/app/settlement/washer/${washerId}`, { state: washerId });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <CardHeader title="Provider Settlement" />
      <Divider />
      <CardContent>
        <Table>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            headCells={headCells}
            onRequestSort={handleRequestSort}
          />
          <TableBody className={classes.tableBody}>
            {loading && <CircularProgress className={classes.loader} />}
            {!loading &&
              settlements &&
              stableSort(settlements, getComparator(order, orderBy)).map(
                (settlement, index) => (
                  <TableRow hover key={settlement.washer_id}>
                    <TableCell>
                      {(pagerInfo.page - 1) * pagerInfo.limit + index + 1}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {settlement.firstname} {settlement.lastname}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{settlement.phonenumber}</TableCell>
                    <TableCell>{settlement.balance}€</TableCell>
                    <TableCell>
                      <SearchIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => goToEdit(settlement.washer_id)}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            {!loading && !settlements && (
              <Typography className={classes.loader}>No Data</Typography>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ProviderSettlement.propTypes = {
  settlements: PropTypes.array,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default ProviderSettlement;
