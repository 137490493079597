import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Box, Card, CardContent, Typography } from '@material-ui/core';

const VehicleTypesToolBar = (props) => {
  const navigate = useNavigate();

  const goToAdd = () => {
    navigate('/app/vehicle-types/add');
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button color="primary" variant="contained" onClick={goToAdd}>
          ADD VEHICLE
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <Typography>Vehicle Types</Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default VehicleTypesToolBar;
