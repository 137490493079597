const Logo = (props) => (
  <img
    style={{height: "3rem"}}
    alt="Logo"
    src="/static/washer-logo.svg"
    {...props}
  />
);

export default Logo;
