import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  FormHelperText,
  TextField,
  Divider,
  CardContent,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

const SmsConfiguration = ({ handleSmsConfiguration }) => {
  const smsConfig = useSelector((state) => state.settings.smsConfig);

  return (
    <Card>
      <CardHeader title="SMS Configuration" />
      <Divider />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            twilio_account_sid: smsConfig ? smsConfig.twilio_account_sid : '',
            twilio_auth_token: smsConfig ? smsConfig.twilio_auth_token : '',
            twilio_number: smsConfig ? smsConfig.twilio_number : '',
            nexmo_api_key: smsConfig ? smsConfig.nexmo_api_key : '',
            nexmo_api_secret: smsConfig ? smsConfig.nexmo_api_secret : '',
            nexmo_api_number: smsConfig ? smsConfig.nexmo_api_number : '',
          }}
          validationSchema={Yup.object().shape({
            twilio_account_sid: Yup.string()
              .max(255)
              .required('Twilio Account Sid is required'),
            twilio_auth_token: Yup.string()
              .max(255)
              .required('Twilio Auth Token is required'),
            twilio_number: Yup.string()
              .max(255)
              .required('Twilio No is required'),
            nexmo_api_key: Yup.string().required('Nexmo API Key is required'),
            nexmo_api_secret: Yup.string().required(
              'Nexmo API Secret is required'
            ),
            nexmo_api_number: Yup.number().required(
              'Nexmo API Number is required'
            ),
          })}
          onSubmit={(values) => {
            handleSmsConfiguration(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <CardHeader title="Twilio" />
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.twilio_account_sid && errors.twilio_account_sid
                    )}
                    fullWidth
                    helperText={
                      touched.twilio_account_sid && errors.twilio_account_sid
                    }
                    label="Twilio Account Sid"
                    name="twilio_account_sid"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twilio_account_sid}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.twilio_auth_token && errors.twilio_auth_token
                    )}
                    fullWidth
                    helperText={
                      touched.twilio_auth_token && errors.twilio_auth_token
                    }
                    label="Twilio Auth Token"
                    name="twilio_auth_token"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twilio_auth_token}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.twilio_number && errors.twilio_number
                    )}
                    fullWidth
                    helperText={touched.twilio_number && errors.twilio_number}
                    label="Twilio no"
                    name="twilio_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twilio_number}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <CardHeader title="Nexmo" />
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.nexmo_api_key && errors.nexmo_api_key
                    )}
                    fullWidth
                    helperText={touched.nexmo_api_key && errors.nexmo_api_key}
                    label="Nexmo API Key"
                    name="nexmo_api_key"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nexmo_api_key}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.nexmo_api_secret && errors.nexmo_api_secret
                    )}
                    fullWidth
                    helperText={
                      touched.nexmo_api_secret && errors.nexmo_api_secret
                    }
                    label="Nexmo API Secret"
                    name="nexmo_api_secret"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nexmo_api_secret}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.nexmo_api_number && errors.nexmo_api_number
                    )}
                    fullWidth
                    helperText={
                      touched.nexmo_api_number && errors.nexmo_api_number
                    }
                    label="Nexmo API Number"
                    name="nexmo_api_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nexmo_api_number}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Box paddingY={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

SmsConfiguration.propTypes = {
  handleSmsConfiguration: PropTypes.func,
};

export default SmsConfiguration;
