import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  FormHelperText,
  Typography,
  TextField,
  Divider,
  CardContent,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

const CURRENCY_SYMBOLS = [
  {
    value: 'pound',
    label: '£',
  },
  {
    value: 'euro',
    label: '€',
  },
  {
    value: 'dollar',
    label: '$',
  },
];

const CURRENCY_LABLES = [
  {
    value: 'pound',
    label: 'POUND',
  },
  {
    value: 'euro',
    label: 'EURO',
  },
  {
    value: 'dollar',
    label: 'DOLLAR',
  },
];

const distanceUnitSymbols = [
  {
    value: 'km',
    label: 'KM',
  },
  {
    value: 'm',
    label: 'M',
  },
];

const europeanNumbers = [
  {
    value: true,
    label: 'Accept',
  },
  {
    value: false,
    label: 'Decline',
  },
];

const GeneralSettings = ({ handleGeneralSetting, settings }) => {
  const editorRef = useRef(null);

  const generalSetting = settings;

  return (
    <Card>
      <CardHeader title="General Settings" />
      <Divider />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            app_name: generalSetting ? generalSetting.app_name : '',
            company_email: generalSetting ? generalSetting.company_email : '',
            company_address: generalSetting
              ? generalSetting.company_address
              : '',
            timezone_offset: generalSetting
              ? generalSetting.timezone_offset
              : '',
            default_password: generalSetting
              ? generalSetting.default_password
              : '',
            number_driver_cancel_per_day: generalSetting
              ? generalSetting.number_driver_cancel_per_day
              : '',
            number_rider_cancel_per_day: generalSetting
              ? generalSetting.number_rider_cancel_per_day
              : '',
            number_driver_called_nearby: generalSetting
              ? generalSetting.number_driver_called_nearby
              : '',
            req_time_accept_decline: generalSetting
              ? generalSetting.req_time_accept_decline
              : '',
            req_time_search_nearby_driver: generalSetting
              ? generalSetting.req_time_search_nearby_driver
              : '',
            default_phone_code: generalSetting
              ? generalSetting.default_phone_code
              : '',
            currency_symbol: generalSetting
              ? generalSetting.currency_symbol
              : '',
            currency_label: generalSetting ? generalSetting.currency_label : '',
            distance_unit_symbol: generalSetting
              ? generalSetting.distance_unit_symbol
              : '',
            distance_unit_label: generalSetting
              ? generalSetting.distance_unit_symbol
              : '',
            european_number_enable: generalSetting
              ? generalSetting.european_number_enable
              : '',
            terms_conditions: generalSetting
              ? generalSetting.terms_conditions
              : '',
          }}
          onSubmit={(values) => {
            const generalSettings = {
              app_name: values.app_name,
              company_email: values.company_email,
              company_address: values.company_address,
              timezone_offset: values.timezone_offset,
              default_password: values.default_password,

              number_driver_cancel_per_day: values.number_driver_cancel_per_day,
              number_rider_cancel_per_day: values.number_rider_cancel_per_day,
              number_driver_called_nearby: values.number_driver_called_nearby,
              req_time_accept_decline: values.req_time_accept_decline,
              req_time_search_nearby_driver:
                values.req_time_search_nearby_driver,
              default_phone_code: values.default_phone_code,
              currency_symbol: values.currency_symbol,
              currency_label: values.currency_label,
              distance_unit_symbol: values.distance_unit_symbol,
              distance_unit_label: values.distance_unit_label,
              european_number_enable: values.european_number_enable,
              terms_conditions: editorRef.current.getContent(),
            };

            handleGeneralSetting(generalSettings);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(touched.app_name && errors.app_name)}
                    fullWidth
                    helperText={touched.app_name && errors.app_name}
                    label="App Name"
                    name="app_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.app_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.default_password && errors.default_password
                    )}
                    fullWidth
                    helperText={
                      touched.default_password && errors.default_password
                    }
                    label="Default Password"
                    name="default_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.default_password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.timezone_offset && errors.timezone_offset
                    )}
                    fullWidth
                    helperText={
                      touched.timezone_offset && errors.timezone_offset
                    }
                    label="Timezone offset"
                    name="timezone_offset"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.timezone_offset}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.default_phone_code && errors.default_phone_code
                    )}
                    fullWidth
                    helperText={
                      touched.default_phone_code && errors.default_phone_code
                    }
                    label="Default Phone Code"
                    name="default_phone_code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.default_phone_code}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.company_address && errors.company_address
                    )}
                    fullWidth
                    helperText={
                      touched.company_address && errors.company_address
                    }
                    label="Company Address"
                    name="company_address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.company_email && errors.company_email
                    )}
                    fullWidth
                    helperText={touched.company_email && errors.company_email}
                    label="Company Mail"
                    name="company_email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.company_email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.number_driver_cancel_per_day &&
                        errors.number_driver_cancel_per_day
                    )}
                    fullWidth
                    helperText={
                      touched.number_driver_cancel_per_day &&
                      errors.number_driver_cancel_per_day
                    }
                    label="No Of Driver Canceled Allowed Per Day (No - 1)"
                    name="number_driver_cancel_per_day"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.number_driver_cancel_per_day}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.number_rider_cancel_per_day &&
                        errors.number_rider_cancel_per_day
                    )}
                    fullWidth
                    helperText={
                      touched.number_rider_cancel_per_day &&
                      errors.number_rider_cancel_per_day
                    }
                    label="No Of Rider Canceled Allowed Per Day (No - 1)"
                    name="number_rider_cancel_per_day"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.number_rider_cancel_per_day}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.number_driver_called_nearby &&
                        errors.number_driver_called_nearby
                    )}
                    fullWidth
                    helperText={
                      touched.number_driver_called_nearby &&
                      errors.number_driver_called_nearby
                    }
                    label="No Of Drivers To be Called Nearby Pickup Point"
                    name="number_driver_called_nearby"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.number_driver_called_nearby}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.req_time_accept_decline &&
                        errors.req_time_accept_decline
                    )}
                    fullWidth
                    helperText={
                      touched.req_time_accept_decline &&
                      errors.req_time_accept_decline
                    }
                    label="Request Time For Single Driver To Accept/Decline (seconds)"
                    name="req_time_accept_decline"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.req_time_accept_decline}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    error={Boolean(
                      touched.req_time_search_nearby_driver &&
                        errors.req_time_search_nearby_driver
                    )}
                    fullWidth
                    helperText={
                      touched.req_time_search_nearby_driver &&
                      errors.req_time_search_nearby_driver
                    }
                    label="Request Time For Rider To Search for Nearby Driver (seconds)"
                    name="req_time_search_nearby_driver"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.req_time_search_nearby_driver}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Currency Symbol"
                    name="currency_symbol"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.currency_symbol}
                    variant="outlined"
                  >
                    {CURRENCY_SYMBOLS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Currency Label"
                    name="currency_label"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.currency_label}
                    variant="outlined"
                  >
                    {CURRENCY_LABLES.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Distance Unit Symbol"
                    name="distance_unit_symbol"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.distance_unit_symbol}
                    variant="outlined"
                  >
                    {distanceUnitSymbols.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Distance Unit Label"
                    name="distance_unit_label"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.distance_unit_label}
                    variant="outlined"
                  >
                    {distanceUnitSymbols.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Is European Number Format Enabled"
                    name="european_number_enable"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.european_number_enable}
                    variant="outlined"
                  >
                    {europeanNumbers.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography align="left" color="textPrimary" variant="h6">
                    Terms and Conditions
                  </Typography>
                  <Editor
                    apiKey="jxa2q9d2niavwinn9jshw9ts429l0iihowbq2cthj8lpmdew"
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    initialValue={values.terms_conditions}
                    onEditorChange={handleChange}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                  />
                </Grid>
              </Grid>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>{errors.policy}</FormHelperText>
              )}
              <Box paddingY={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      UPDATE
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

GeneralSettings.propTypes = {
  handleGeneralSetting: PropTypes.func,
  settings: PropTypes.func,
};
export default GeneralSettings;
