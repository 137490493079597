import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { api } from 'src/service';
import VehiclesListResults from '../../components/vehicles/VehiclesListResults';
import VehiclesToolBar from '../../components/vehicles/VehiclesToolBar';
import { getVehiclesList, removeVehicle } from '../../redux/vehicles/action';

const Vehicles = () => {
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [vehicleID, setVehicleID] = useState();
  const [searchStr, setSearchStr] = useState('');
  const [pagerInfo, setPagerInfo] = useState({
    limit: 10,
    page: 1,
    totalSize: 0,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api
          .post('/backoffice/v2/vehicle_client/filter', {
            page_size: pagerInfo.limit,
            current_page: pagerInfo.page,
            search: searchStr,
          })
          .then((res) => {
            setVehicles(res.data.data.data);
            setPagerInfo({
              limit: res.data.data.page_size,
              page: res.data.data.current_page,
              totalSize: res.data.data.total_size,
            });
          });
      } catch (error) {
        const message = error.response
          ? error.response.data.moreInformation.error
          : { message: error.message };
        throw new Error(message);
      }
      setLoading(false);
    };

    fetchData();
  }, [searchStr, pagerInfo.page, pagerInfo.limit]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleRow = async (id) => {
    handleOpen(true);
    setVehicleID(id);
  };

  const handleRemove = async () => {
    setLoading(true);
    setOpen(false);
    await dispatch(removeVehicle(vehicleID));
    const data = await dispatch(getVehiclesList());
    setVehicles(data);
    setLoading(false);
  };

  const handleSearchStr = (str) => {
    setSearchStr(str);
  };

  const handlePagerInfo = (info) => {
    setPagerInfo(info);
  };

  return (
    <>
      <Helmet>
        <title>Washer - Client Vehicles</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <VehiclesToolBar handleSearchStr={handleSearchStr} />
          <Box sx={{ pt: 3 }}>
            <VehiclesListResults
              vehicles={vehicles}
              handleRow={handleRow}
              pagerInfo={pagerInfo}
              handlePagerInfo={handlePagerInfo}
              loading={loading}
              actionHandler
            />
          </Box>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={handleRemove} color="primary" autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Vehicles;
