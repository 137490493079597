import { SET_ADMIN_LIST, SET_ADMIN_INFO } from './constant';

const DEFAULT_STATE = {
  list: null,
  info: null
};

const adminsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_ADMIN_LIST:
      return { ...state, list: action.payload };
    case SET_ADMIN_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default adminsReducer;
