import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DelteIcon from '@material-ui/icons/Delete';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    sortable: true,
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
    sortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email Address',
    sortable: true,
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    sortable: true,
  },
  {
    id: 'operation_range',
    numeric: false,
    disablePadding: false,
    label: 'Operation Range',
    sortable: true,
  },
  {
    id: 'prefix',
    numeric: false,
    disablePadding: false,
    label: 'Country Code',
    sortable: true,
  },
  {
    id: 'phonenumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
    sortable: true,
  },
  {
    id: 'nif',
    numeric: false,
    disablePadding: false,
    label: 'NIF',
    sortable: true,
  },
  {
    id: 'cc',
    numeric: false,
    disablePadding: false,
    label: 'CC',
    sortable: true,
  },
  {
    id: 'iban',
    numeric: false,
    disablePadding: false,
    label: 'IBAN',
    sortable: true,
  },
  {
    id: 'commission',
    numeric: false,
    disablePadding: false,
    label: 'Commission',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const WasherListResult = ({
  washers,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToEdit = (id) => {
    navigate(`/app/washers/edit/${id}`, { state: id });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                washers &&
                stableSort(washers, getComparator(order, orderBy)).map(
                  (user, index) => (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        {(pagerInfo.page - 1) * pagerInfo.limit + index + 1}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {user.firstname}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{user.lastname}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.address}</TableCell>
                      <TableCell>{user.operation_range}</TableCell>
                      <TableCell>{user.prefix}</TableCell>
                      <TableCell>{user.phonenumber}</TableCell>
                      <TableCell>{user.nif}</TableCell>
                      <TableCell>{user.cc}</TableCell>
                      <TableCell>{user.iban}</TableCell>
                      <TableCell>{user.commission}</TableCell>
                      <TableCell>{user.status}</TableCell>
                      <TableCell>
                        <>
                         <EditIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => goToEdit(user.id)}
                          />
                        </>
                        {user.status !== 'inactive' && (
                          <>
                            <DelteIcon
                              style={{ cursor: 'pointer', color: 'indianred' }}
                              onClick={() => handleRow(user.id)}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!loading && !washers && (
                <Typography className={classes.loader}>No Data</Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

WasherListResult.propTypes = {
  washers: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default WasherListResult;
