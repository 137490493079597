import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  FormHelperText,
  TextField,
  Divider,
  CardContent,
} from '@material-ui/core';

const selectNotificationTo = [
  {
    value: 'all washers',
    label: 'All Washers',
  },
  {
    value: 'online washers',
    label: 'Online Washers',
  },
  {
    value: 'all users',
    label: 'All Users',
  },
  {
    value: 'inactive users',
    label: 'Inactive Users',
  },
];

const SmsConfiguration = (props) => (
  <Card>
    <CardHeader title="Send SMS Notification" />
    <Divider />
    <CardContent>
      <Formik
        initialValues={{
          send_to: selectNotificationTo[0].value,
          message: '',
        }}
        validationSchema={Yup.object().shape({
          send_to: Yup.string().required('Please select one'),
          message: Yup.string().required('Please enter a message'),
        })}
        onSubmit={(values) => {
          props.handleSmsNotification(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Select Notification To"
                  name="handleSmsNotification"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.handleSmsNotification}
                  variant="outlined"
                >
                  {selectNotificationTo.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  error={Boolean(touched.message && errors.message)}
                  fullWidth
                  helperText={touched.message && errors.message}
                  label="Enter Message"
                  name="message"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.message}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {Boolean(touched.policy && errors.policy) && (
              <FormHelperText error>{errors.policy}</FormHelperText>
            )}
            <Box paddingY={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    SEND SMS NOTIFICATION
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </CardContent>
  </Card>
);

SmsConfiguration.propTypes = {
  handleSmsNotification: PropTypes.func,
};

export default SmsConfiguration;
