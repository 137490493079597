import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { getServiceByID, updateServiceByID } from 'src/redux/services/action';
import { useToast } from 'src/contexts/toast';
import CustomImageInput from './CustomImageInput';

const EditService = () => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [imgBase64, setImgBase64] = useState();
  const [imgSelectedBase64, setImgSelectedBase64] = useState();
  const [service, setService] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const dataServices = await dispatch(getServiceByID(params.serviceId));
      setService(dataServices[0]);
      setLoading(false);
    };
    fetchData();
  }, [params.serviceId]);

  const goBack = () => {
    navigate('/app/services');
  };

  const handleBase64 = (src) => {
    setImgBase64(src);
  };

  const handleSelectedBase64 = (src) => {
    setImgSelectedBase64(src);
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card style={{ display: 'flex' }}>
              <CardHeader title="Edit Service" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {loading && <CircularProgress />}
                {!loading && (
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          acronym: service ? service.acronym : '',
                          service_name: service ? service.service_name : '',
                          price_class_1: service ? service.price_class_1 : '',
                          price_class_2: service ? service.price_class_2 : '',
                          description: service ? service.description : '',
                          service_description_pt: service ? service.pt : '',
                          service_description_en: service ? service.en : '',
                          service_order: service ? service.service_order : '',
                          image: service ? service.image : '',
                          image_selected: service ? service.image_selected : '',
                        }}
                        validationSchema={Yup.object().shape({
                          acronym: Yup.string()
                            .max(255)
                            .required('Acronym is required'),
                          service_name: Yup.string()
                            .max(255)
                            .required('Name of the Service is required'),
                          price_class_1: Yup.number().required(
                            'Price Class 1 is required'
                          ),
                          price_class_2: Yup.number().required(
                            'Price Class 2 is required'
                          ),
                          description: Yup.string()
                            .required('Service Description is required')
                            .max(255),
                          service_description_pt: Yup.string().required(
                            'PT service Info is required'
                          ),
                          service_description_en: Yup.string().required(
                            'EN service Info is required'
                          ),
                          service_order: Yup.number().required(
                            'Order is required'
                          ),
                        })}
                        onSubmit={async (values) => {
                          try {
                            // eslint-disable-next-line no-param-reassign
                            values.image = imgBase64;
                            // eslint-disable-next-line no-param-reassign
                            values.image_selected = imgSelectedBase64;

                            await dispatch(
                              updateServiceByID(values, params.serviceId)
                            );
                            showToast('Service is updated successfully!');
                            navigate('/app/services', { replace: true });
                          } catch (error) {
                            showToast(error.message, 'warning');
                          }
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              error={Boolean(touched.acronym && errors.acronym)}
                              fullWidth
                              helperText={touched.acronym && errors.acronym}
                              label="Acronym"
                              margin="normal"
                              name="acronym"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.acronym}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.service_name && errors.service_name
                              )}
                              fullWidth
                              helperText={
                                touched.service_name && errors.service_name
                              }
                              label="Enter Name of the Service"
                              margin="normal"
                              name="service_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.service_name}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.price_class_1 && errors.price_class_1
                              )}
                              fullWidth
                              helperText={
                                touched.price_class_1 && errors.price_class_1
                              }
                              label="Enter Price Class 1"
                              margin="normal"
                              name="price_class_1"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={values.price_class_1}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.price_class_2 && errors.price_class_2
                              )}
                              fullWidth
                              helperText={
                                touched.price_class_2 && errors.price_class_2
                              }
                              label="Enter Price Class 2"
                              margin="normal"
                              name="price_class_2"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={values.price_class_2}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              fullWidth
                              helperText={
                                touched.description && errors.description
                              }
                              label="Enter Description"
                              margin="normal"
                              name="description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.description}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.service_description_pt &&
                                  errors.service_description_pt
                              )}
                              fullWidth
                              helperText={
                                touched.service_description_pt &&
                                errors.service_description_pt
                              }
                              label="Enter Service Info PT"
                              margin="normal"
                              name="service_description_pt"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.service_description_pt}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.service_description_en &&
                                  errors.service_description_en
                              )}
                              fullWidth
                              helperText={
                                touched.service_description_en &&
                                errors.service_description_en
                              }
                              label="Enter Service Info EN"
                              margin="normal"
                              name="service_description_en"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.service_description_en}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.service_order && errors.service_order
                              )}
                              fullWidth
                              helperText={
                                touched.service_order && errors.service_order
                              }
                              label="Service Order to Show"
                              margin="normal"
                              name="service_order"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="number"
                              value={values.service_order}
                              variant="outlined"
                            />
                            <div
                              style={{
                                backgroundImage: `url(${
                                  service ? service.image : ''
                                })`,
                                width: 100,
                                height: 100,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginTop: 10,
                              }}
                            />
                            <Field
                              name="image"
                              component={CustomImageInput}
                              title="Select an image"
                              handleBase64={handleBase64}
                              setFieldValue={setFieldValue}
                              errorMessage={
                                errors.image ? errors.image : undefined
                              }
                              touched={touched.image}
                              style={{ display: 'flex' }}
                              onBlur={handleBlur}
                            />

                            <div
                              style={{
                                backgroundImage: `url(${
                                  service ? service.image_selected : ''
                                })`,
                                width: 100,
                                height: 100,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                marginTop: 10,
                                backgroundColor: '#56d0de',
                              }}
                            />
                            <Field
                              name="image_selected"
                              component={CustomImageInput}
                              title="Select an image when selected"
                              handleBase64={handleSelectedBase64}
                              setFieldValue={setFieldValue}
                              errorMessage={
                                errors.image_selected
                                  ? errors.image_selected
                                  : undefined
                              }
                              touched={touched.image_selected}
                              style={{ display: 'flex' }}
                              onBlur={handleBlur}
                            />

                            <Box paddingY={3}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                  >
                                    UPDATE
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    onClick={goBack}
                                    variant="contained"
                                  >
                                    GO BACK
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EditService;
