import { SET_VEHICLES_LIST, SET_VEHICLES_INFO } from './constant';
import { api } from '../../service';

/**
 * Get vehicles list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getVehiclesList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/backoffice/v2/vehicle_client');

    if (data) {
      // Dispatch Vehicles List Info.
      dispatch({ type: SET_VEHICLES_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get vehicle by ID.
 *
 * @param {string} vehicleID
 * @returns
 */
export const getVehicleByID = (vehicleID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/backoffice/v2/vehicle_client/${vehicleID}`);
    if (data) {
      dispatch({ type: SET_VEHICLES_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update vehicle by ID.
 *
 * @param {object} values
 * @param {string} vehicleID
 * @returns
 */
export const updateVehicleByID = (values, vehicleID) => async () => {
  try {
    const { data } = await api.patch(`/backoffice/v2/vehicle_client/${vehicleID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add vehicle.
 *
 * @param {object} values
 * @returns
 */
export const createVehicle = (values) => async () => {
  try {
    const { data } = await api.post('/backoffice/vehicle_client', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove vehicle by ID.
 *
 * @param {string} vehicleID
 * @returns
 */
export const removeVehicle = (vehicleID) => async () => {
  try {
    const { data } = await api.delete(`/backoffice/v2/vehicle_client/${vehicleID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
