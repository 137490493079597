import { SET_GENERAL_SETTING, SET_SMS_CONFIGURATION } from './constant';

const DEFAULT_STATE = {
  general: null,
  smsConfig: null
};

const settingsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_GENERAL_SETTING:
      return { ...state, general: action.payload[0] };
    case SET_SMS_CONFIGURATION:
      return { ...state, smsConfig: action.payload[0] };
    default:
      return state;
  }
};

export default settingsReducer;
