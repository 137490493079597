import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  updateGeneralServiceSettings,
  getGeneralSetting,
} from 'src/redux/settings/action';
import { useToast } from 'src/contexts/toast';
import Autocomplete from '@material-ui/lab/Autocomplete';
import listaConcelhos from 'src/utils/concelhos';

const ServiceSettings = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [settings, setSettings] = useState();

  const [selectedConcelhos, setSelectedConcelhos] = useState([]);

  useEffect(async () => {
    setLoading(true);

    const dataServices = await dispatch(getGeneralSetting());

    setSettings(dataServices.settings[0]);

    setLoading(false);

    setSelectedConcelhos(dataServices.array);
  }, [setSelectedConcelhos]);

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card style={{ display: 'flex' }}>
              <CardHeader title="Edit Service" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {loading && <CircularProgress />}
                {!loading && (
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          commission_perc: settings
                            ? settings.commission_perc
                            : '',
                          extra_cost: settings ? settings.extra_cost : '',
                          cancel_price: settings ? settings.cancel_price : '',
                          cancel_time: settings ? settings.cancel_time : '',
                          max_req_radius: settings
                            ? settings.max_req_radius
                            : '',
                        }}
                        validationSchema={Yup.object().shape({
                          commission_perc: Yup.number().required(
                            'Default commission is required'
                          ),
                          extra_cost: Yup.number().required(
                            'Default extra price is required'
                          ),
                          cancel_price: Yup.number().required(
                            'Default calcelation price is required'
                          ),
                          cancel_time: Yup.number().required(
                            'Default cancelation time is required'
                          ),
                          max_req_radius: Yup.number().required(
                            'Default cancelation time is required'
                          ),
                        })}
                        onSubmit={async (values) => {
                          // onSubmit={async (values) => {
                          try {
                            const payload = {
                              values,
                              concelhos: selectedConcelhos,
                            };
                            await dispatch(
                              updateGeneralServiceSettings(payload)
                            );
                            showToast('Service settings updated successfully!');
                          } catch (error) {
                            console.error(error.message);
                          }
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              error={Boolean(
                                touched.commission_perc &&
                                  errors.commission_perc
                              )}
                              fullWidth
                              helperText={
                                touched.commission_perc &&
                                errors.commission_perc
                              }
                              label="Default comission (%)"
                              margin="normal"
                              name="commission_perc"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.commission_perc}
                              variant="outlined"
                              type="number"
                            />
                            <TextField
                              error={Boolean(
                                touched.extra_cost && errors.extra_cost
                              )}
                              fullWidth
                              helperText={
                                touched.extra_cost && errors.extra_cost
                              }
                              label="Default extra cost (Euros €)"
                              margin="normal"
                              name="extra_cost"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.extra_cost}
                              variant="outlined"
                              type="number"
                            />
                            <TextField
                              error={Boolean(
                                touched.cancel_price && errors.cancel_price
                              )}
                              fullWidth
                              helperText={
                                touched.cancel_price && errors.cancel_price
                              }
                              label="Default cancelation price (Euros €)"
                              margin="normal"
                              name="cancel_price"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.cancel_price}
                              variant="outlined"
                              type="number"
                            />
                            <TextField
                              error={Boolean(
                                touched.cancel_time && errors.cancel_time
                              )}
                              fullWidth
                              helperText={
                                touched.cancel_time && errors.cancel_time
                              }
                              label="Default cancelation time (seconds)"
                              margin="normal"
                              name="cancel_time"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.cancel_time}
                              variant="outlined"
                              type="number"
                            />
                            <TextField
                              error={Boolean(
                                touched.max_req_radius && errors.max_req_radius
                              )}
                              fullWidth
                              helperText={
                                touched.max_req_radius && errors.max_req_radius
                              }
                              label="Max Request Radius(Km) (Distance b/t Pickup point and Driver location)"
                              margin="normal"
                              name="max_req_radius"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="number"
                              value={values.max_req_radius}
                              variant="outlined"
                            />

                            <Grid item xs={12} md={12} sm={12}>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={listaConcelhos || []}
                                value={selectedConcelhos}
                                getOptionSelected={(option) =>
                                  option === listaConcelhos.designacao
                                }
                                getOptionLabel={(option) => option.designacao}
                                onChange={(e, value) => {
                                  setSelectedConcelhos(value);
                                }}
                                filterSelectedOptions
                                endAdornment={false}
                                renderInput={(param) => (
                                  <TextField
                                    {...param}
                                    variant="outlined"
                                    label="Select Regions"
                                    placeholder="Favorites"
                                  />
                                )}
                              />
                            </Grid>
                            <Box paddingY={3}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                  >
                                    UPDATE
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ServiceSettings;
