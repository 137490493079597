import axios from 'axios';
import { get, remove } from '../utils/storage';
import logger from '../utils/logger';
import { MAIN_TOKEN_KEY } from '../constants';

const baseApi = axios.create({
  baseURL: "https://europe-west1-app-washer-pt.cloudfunctions.net/api",
});

baseApi.interceptors.request.use(
  async (config) => {
    // Do something before request is sent, like we're inserting a autherization header
    const token = get(MAIN_TOKEN_KEY);
    // eslint-disable-next-line no-param-reassign
    if (token) config.headers.Authorization = `Bearer ${token.split('"')[1]}`;

    logger.debug(config.method, config.url, {
      data: config.data,
      header: config.headers,
    });

    return config;
  },
  (error) => {
    // Do something with request error
    logger.error(error);

    return Promise.reject(error);
  }
);

baseApi.interceptors.response.use(
  undefined,
  (err) => {
    if (err.response.status === 401 || err.response.status === 403 && err.response.data.error === 'Unauthorized') {
      remove(MAIN_TOKEN_KEY);
      window.location.href = '/login';
    }
    return Promise.reject(err);
  }
);

export default baseApi;
