import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles,
  Badge,
} from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Search';
import PaidIcon from '@material-ui/icons/Paid';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
    sortable: true,
  },
  {
    id: 'phonenumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
    sortable: true,
  },
  {
    id: 'balance',
    numeric: false,
    disablePadding: false,
    label: 'Balance',
    sortable: true,
  },
  {
    id: 'unpaid_count',
    numeric: false,
    disablePadding: false,
    label: 'View/Add Extra',
    sortable: true,
  },
  {
    id: 'payments',
    numeric: false,
    disablePadding: false,
    label: 'Payments',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const WasherPaymentsListResult = ({
  washers,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToWasherPayments = (id) => {
    navigate(`/app/payments/washer/${id}`, { state: id });
  };

  const goToExtraPayments = (id) => {
    navigate(`/app/payments/extra/${id}`, { state: id });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                washers &&
                stableSort(washers, getComparator(order, orderBy)).map(
                  (user, index) => (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        {(pagerInfo.page - 1) * pagerInfo.limit + index + 1}
                      </TableCell>
                      <TableCell>
                        {user.firstname} {user.lastname}
                      </TableCell>
                      <TableCell>
                        {user.prefix} {user.phonenumber}
                      </TableCell>
                      <TableCell>{user.balance}</TableCell>
                      {user.unpaid_count ? (
                        <TableCell>
                          <Badge
                            color="secondary"
                            badgeContent={user.unpaid_count}
                            style={{ cursor: 'pointer' }}
                            onClick={() => goToExtraPayments(user.id)}
                          >
                            <PaidIcon />
                          </Badge>
                        </TableCell>
                      ) : (
                        <TableCell>
                          {user.status !== 'inactive' && (
                            <>
                              <Badge
                                color="secondary"
                                badgeContent={0}
                                style={{ cursor: 'pointer' }}
                                onClick={() => goToExtraPayments(user.id)}
                              >
                                <PaidIcon />
                              </Badge>
                            </>
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        {user.status !== 'inactive' && (
                          <>
                            <DetailsIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => goToWasherPayments(user.id)}
                            />
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!loading && !washers && (
                <p className={classes.loader}>
                  <Typography>No Data</Typography>
                </p>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

WasherPaymentsListResult.propTypes = {
  washers: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default WasherPaymentsListResult;
