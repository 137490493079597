import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Button,
  Grid,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

// eslint-disable-next-line react/prop-types
const AdminsToolBar = ({ handleSearchStr }) => {
  const navigate = useNavigate();

  const goToAdd = () => {
    navigate('/app/services/add');
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={10} sm={10}>
                <Box sx={{ maxWidth: 500 }}>
                  <TextField
                    fullWidth
                    onChange={(e) => handleSearchStr(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search service"
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sm={2}
                sx={{ alignSelf: 'center', paddingRight: '3rem' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button color="primary" variant="contained" onClick={goToAdd}>
                    ADD SERVICE
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default AdminsToolBar;
