import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Search';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Order Id' },
  {
    id: 'service_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
    sortable: true,
  },
  {
    id: 'washer_firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
    sortable: true,
  },
  {
    id: 'status_order',
    numeric: false,
    disablePadding: false,
    label: 'Order Status',
    sortable: true,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'Price',
    sortable: true,
  },
  {
    id: 'commission',
    numeric: false,
    disablePadding: false,
    label: 'Commssion',
    sortable: true,
  },
  {
    id: 'pay_method',
    numeric: false,
    disablePadding: false,
    label: 'Pay Method',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Pay Status',
    sortable: false,
  },
  {
    id: 'start_service_date',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SettlementByWasherListResult = ({
  payments,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const goToDetails = (id) => {
    navigate(`/app/jobs/edit/${id}`, { state: id });
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody className={classes.tableBody}>
                {loading && <CircularProgress className={classes.loader} />}
                {!loading &&
                  payments &&
                  stableSort(payments, getComparator(order, orderBy)).map(
                    (user) => (
                      <TableRow hover key={user.order_id}>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.sevice_name}</TableCell>
                        <TableCell>
                          {`${user.washer_firstname} ${user.washer_lastname}`}
                        </TableCell>
                        <TableCell>{user.status_order}</TableCell>
                        <TableCell>{user.price}</TableCell>
                        <TableCell>{user.commission}</TableCell>
                        <TableCell>{user.pay_method}</TableCell>
                        <TableCell>{user.status}</TableCell>
                        <TableCell>{user.start_service_date}</TableCell>
                        <TableCell>
                          <DetailsIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => goToDetails(user.id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                {!loading && !payments && (
                  <p className={classes.loader}>
                    <Typography>No Data</Typography>
                  </p>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={pagerInfo ? pagerInfo.totalSize : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={pagerInfo ? pagerInfo.page - 1 : 0}
          rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

SettlementByWasherListResult.propTypes = {
  payments: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default SettlementByWasherListResult;
