import { SET_VEHICLES_MODEL_LIST, SET_VEHICLES_MODEL_INFO } from './constant';
import { api } from '../../service';

/**
 * Get models list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getModelsList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/vehicle_model');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_VEHICLES_MODEL_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get model by ID.
 *
 * @param {string} modelID
 * @returns
 */
export const getModelByID = (modelID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/vehicle_model/${modelID}`);
    if (data) {
      dispatch({ type: SET_VEHICLES_MODEL_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update model by ID.
 *
 * @param {object} values
 * @param {string} modelID
 * @returns
 */
export const updateModelByID = (values, modelID) => async () => {
  try {
    const { data } = await api.patch(`/vehicle_model/${modelID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add model.
 *
 * @param {object} values
 * @returns
 */
export const createModel = (values) => async () => {
  try {
    const { data } = await api.post('/vehicle_model', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove model by ID.
 *
 * @param {string} modelID
 * @returns
 */
export const removeModel = (modelID) => async () => {
  try {
    const { data } = await api.delete(`/vehicle_model/${modelID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
