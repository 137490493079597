import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ children }) => <p style={{ color: 'red' }}>{children}</p>;

Error.propTypes = {
  children: PropTypes.object
};

export default Error;
