import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  getTranslations,
  getSmsConfiguration,
  updateTranslations,
} from 'src/redux/settings/action';
import { useToast } from 'src/contexts/toast';
import GeneralSettings from '../components/translations/GeneralSettings';

const Translations = () => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const settings2 = dispatch(getTranslations());
    settings2.then((result) => {
      setSettings(result);
    });

    dispatch(getSmsConfiguration());
  }, []);

  const handleGeneralSetting = (values) => {
    const obj = [
      {
        key: 'parking_address',
        pt: values.parking_address_pt,
        en: values.parking_address_en,
      },
      {
        key: 'welcome_msg_1',
        pt: values.welcome_msg_1_pt,
        en: values.welcome_msg_1_en,
      },
      {
        key: 'welcome_msg_2',
        pt: values.welcome_msg_2_pt,
        en: values.welcome_msg_2_en,
      },
      {
        key: 'services_info_msg_1',
        pt: values.services_info_msg_1_pt,
        en: values.services_info_msg_1_en,
      },
      {
        key: 'services_info_msg_2',
        pt: values.services_info_msg_2_pt,
        en: values.services_info_msg_2_en,
      },
    ];
    dispatch(updateTranslations(obj));
    showToast('Translations updated successfully!');
  };

  return (
    <>
      <Helmet>
        <title>Washer - Translations</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            {settings && (
              <GeneralSettings
                handleGeneralSetting={handleGeneralSetting}
                settings={settings}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Translations;
