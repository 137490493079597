import { SET_VEHICLES_MAKE_LIST, SET_VEHICLES_MAKE_INFO } from './constant';

const DEFAULT_STATE = {
  list: null,
  info: null
};

const makeReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_VEHICLES_MAKE_LIST:
      return { ...state, list: action.payload };
    case SET_VEHICLES_MAKE_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default makeReducer;
