import React from 'react';
import {
  Box,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Badge,
  Typography,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import PersonIcon from '@material-ui/icons/Person';

// eslint-disable-next-line react/prop-types
const WasherListToolbar = ({ handleSearchStr, newWashers, handleSearch }) => (
  <Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={10} sm={10}>
              <Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  onChange={(e) => handleSearchStr(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search washer"
                  variant="outlined"
                />
              </Box>
            </Grid>
            {newWashers !== 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sm={2}
                  sx={{
                    alignSelf: 'center',
                    paddingRight: '3rem',
                    cursor: 'pointer',
                  }}
                  onClick={handleSearch}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingRight: '3rem',
                    }}
                  >
                    <Badge color="secondary" badgeContent={newWashers}>
                      <PersonIcon />
                    </Badge>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      NEW WASHERS
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default WasherListToolbar;
