import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import {
  getJobByID,
  killJobById,
  killJobWithFeeById,
} from 'src/redux/jobs/action';
import { getVehicleByID } from 'src/redux/vehicles/action';
import SingleLineImageList from './SingleLineImageList';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  large: {
    width: `${theme.spacing(18)} !important`,
    height: `${theme.spacing(18)} !important`,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  detailButton: {
    position: 'absolute !important',
    right: 5,
    bottom: 5,
  },
}));

const EditJob = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [job, setJob] = useState(null);
  const [vehicle, setVehicle] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      dispatch(getJobByID(params.jobId)).then((res) => {
        const vehicleID = res.order[0].vehicle_id;
        setJob(res);
        dispatch(getVehicleByID(vehicleID)).then((vehicleData) => {
          setVehicle(vehicleData[0]);
          setLoading(false);
        });
      });
    };
    fetchData();
  }, [params.jobId]);

  const goBack = () => {
    navigate('/app/jobs');
  };

  const killOrder = () => {
    dispatch(killJobById(params.jobId)).then((response) => {
      console.log(response);
    });
    navigate('/app/jobs');
  };
  const killOrderWithFee = () => {
    dispatch(killJobWithFeeById(params.jobId)).then((response) => {
      console.log(response);
    });
    // navigate('/app/jobs');
  };

  const goToServiceDetail = () => {
    navigate(`/app/services/edit/${job && job.order[0].service_id}`, {
      state: job && job.order[0].service_id,
    });
  };

  const goToClientDetail = () => {
    if (job) {
      const clientID = job.order[0].client_id;

      navigate(`/app/clients/edit/${clientID}`, {
        state: clientID,
      });
    }
  };

  const goToWasherDetail = () => {
    if (job && job.order[0].washer_id) {
      const washerID = job.order[0].washer_id;
      navigate(`/app/washers/edit/${washerID}`, {
        state: washerID,
      });
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Box paddingTop={3}>
          <Card>
            <CardHeader title="Job Information" />
          </Card>
        </Box>
        <Box paddingTop={3}>
          <Card>
            {loading && <CircularProgress />}
            {!loading && (
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  paddingBottom={5}
                >
                  <Grid item xs={12} sm={6} md={2}>
                    <Card>
                      <CardContent>
                        <Typography>
                          Booking No: {job && job.order[0].order_id}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Card>
                      <CardContent>
                        <Typography>
                          Status: {job && job.order[0].status_order}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Card>
                      <CardContent>
                        <Typography>
                          {job && job.order[0].creation_date}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card>
                      <CardContent>
                        <Typography>{job && job.order[0].address}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card>
                      <CardContent>
                        <Typography>Tip: {job && job.order[0].tip}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Card>
                      <CardContent>
                        <Typography>Cancel Price: {job && job.order[0].cancel_price}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  paddingBottom={5}
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <Card className={classes.root}>
                      <Avatar
                        alt="Remy Sharp"
                        src={job && job.order[0].service_image}
                        className={classes.large}
                        style={{ borderRadius: 0 }}
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Service
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].service_name}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].service_description}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].service_price}€
                          </Typography>
                          <Button
                            className={classes.detailButton}
                            width={60}
                            onClick={goToServiceDetail}
                            variant="contained"
                            disabled={!job}
                          >
                            Detail
                          </Button>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card className={classes.root}>
                      <Avatar
                        alt="Remy Sharp"
                        src={job && job.client_image[0].url_image}
                        className={classes.large}
                        style={{ borderRadius: 0 }}
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Client
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].client_firstname}{' '}
                            {job && job.order[0].client_lastname}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].client_email}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].client_phonenumber}
                          </Typography>
                          <Button
                            className={classes.detailButton}
                            width={60}
                            onClick={goToClientDetail}
                            variant="contained"
                            disabled={!job}
                          >
                            Detail
                          </Button>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Card className={classes.root}>
                      <Avatar
                        alt="Remy Sharp"
                        src={job && job.washer_image[0].url_image}
                        className={classes.large}
                        style={{ borderRadius: 0 }}
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Washer
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].washer_firstname}{' '}
                            {job && job.order[0].washer_lastname}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].washer_email}
                          </Typography>
                          <Typography variant="subtitle1" color="textSecondary">
                            {job && job.order[0].washer_phonenumber}
                          </Typography>
                          <Button
                            className={classes.detailButton}
                            width={60}
                            onClick={goToWasherDetail}
                            variant="contained"
                            disabled={!job}
                          >
                            Detail
                          </Button>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  paddingBottom={5}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Card className={classes.root} style={{ height: 180 }}>
                      <Avatar
                        alt="Remy Sharp"
                        src={job && job.client_vehicle_image[0].url_image}
                        className={classes.large}
                        style={{ borderRadius: 0 }}
                      />
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Vehicle Information
                          </Typography>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>License Plate</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Colour</TableCell>
                                <TableCell>Class</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow hover>
                                <TableCell>
                                  {vehicle && vehicle.license_plate}
                                </TableCell>
                                <TableCell>
                                  {vehicle && vehicle.description}
                                </TableCell>
                                <TableCell>
                                  {vehicle && vehicle.colour}
                                </TableCell>
                                <TableCell>
                                  {vehicle && vehicle.class}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Card className={classes.root} style={{ height: 180 }}>
                      <div className={classes.details}>
                        <CardContent className={classes.content}>
                          <Typography component="h5" variant="h5">
                            Invoice Information
                          </Typography>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Price</TableCell>
                                <TableCell>Washer Commission</TableCell>
                                <TableCell>Payment Status</TableCell>
                                <TableCell>Invoice</TableCell>
                                <TableCell>Type</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow hover>
                                <TableCell>
                                  {job && job.order[0].service_price}€
                                </TableCell>
                                <TableCell>
                                  {job && job.order[0].washer_commission}€
                                </TableCell>
                                <TableCell>
                                  {job && job.order[0].payment_status}
                                </TableCell>
                                {job && job.order[0].invoice_url ? (
                                  <TableCell>
                                    <a
                                      href={job.order[0].invoice_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      INVOICE
                                    </a>
                                  </TableCell>
                                ) : (
                                  <TableCell>-</TableCell>
                                )}
                                 <TableCell>
                                  Wash Service
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-start"
                  paddingBottom={5}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography component="h5" variant="h5">
                      Before Images
                    </Typography>
                    <Card className={classes.root}>
                      <div
                        className={classes.details}
                        style={{ height: 400, overflowY: 'auto' }}
                      >
                        <CardContent className={classes.content}>
                          <SingleLineImageList
                            itemData={job && job.order_before_image}
                          />
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography component="h5" variant="h5">
                      After Images
                    </Typography>
                    <Card className={classes.root}>
                      <div
                        className={classes.details}
                        style={{ height: 400, overflowY: 'auto' }}
                      >
                        <CardContent className={classes.content}>
                          <SingleLineImageList
                            itemData={job && job.order_after_image}
                          />
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <div>
                  <Button
                    color="primary"
                    size="large"
                    width={60}
                    onClick={goBack}
                    variant="contained"
                  >
                    GO BACK
                  </Button>
                  {job &&
                  job.order[0] &&
                  (job.order[0].status_order === 'payment' ||
                    job.order[0].status_order === 'searching' ||
                    job.order[0].status_order === 'ongoing') ? (
                    <span>
                      <Button
                        color="error"
                        size="large"
                        width={60}
                        onClick={killOrder}
                        variant="contained"
                        style={{
                          float:
                            'right',
                        }}
                      >
                        KILL ORDER NO FEE
                      </Button>
                      <Button
                        color="error"
                        size="large"
                        width={60}
                        onClick={killOrderWithFee}
                        variant="contained"
                        style={{ float: 'right', margin: '0 10px' }}
                      >
                        KILL ORDER WITH FEE
                      </Button>
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </CardContent>
            )}
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default EditJob;
