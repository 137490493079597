import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useToast } from 'src/contexts/toast';
import { getVehicleByID, updateVehicleByID } from 'src/redux/vehicles/action';
import ImageVehicle from 'src/icons/car-example-big.png';

const CLASS_NAMES = [
  {
    id: 1,
    label: 'Class 1',
  },
  {
    id: 2,
    label: 'Class 2',
  },
];

const EditVehicle = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { showToast } = useToast();
  const [currentVehicle, setVehicle] = useState();

  useEffect(() => {
    setLoading(true);
    Promise.all([dispatch(getVehicleByID(params.vehicleId))]).then(
      ([dataVehicle]) => {
        setVehicle(dataVehicle);
        setLoading(false);
      }
    );
  }, [params.vehicleId]);

  const goBack = () => {
    navigate('/app/vehicles');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Edit Client Vehicle" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                {loading && <CircularProgress />}
                {!loading && (
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          description: currentVehicle
                            ? currentVehicle[0].description
                            : '',
                          class: currentVehicle ? currentVehicle[0].class : '',
                          colour: currentVehicle
                            ? currentVehicle[0].colour
                            : '',
                          license_plate: currentVehicle
                            ? currentVehicle[0].license_plate
                            : '',
                        }}
                        validationSchema={Yup.object().shape({
                          description: Yup.string()
                            .max(50)
                            .required('Description is required'),
                          class: Yup.string()
                            .max(255)
                            .required('Class is required'),
                          colour: Yup.string()
                            .max(30)
                            .required('Color is required'),
                          license_plate: Yup.string()
                            .max(255)
                            .required('License plate is required'),
                        })}
                        onSubmit={async (values) => {
                          try {
                            await dispatch(
                              updateVehicleByID(values, params.vehicleId)
                            );
                            showToast('Vehicle type is updated successfully!');
                            navigate('/app/vehicles', { replace: true });
                          } catch (error) {
                            showToast(error.message, 'warning');
                          }
                        }}
                      >
                        {({
                          touched,
                          errors,
                          handleChange,
                          handleSubmit,
                          handleBlur,
                          isSubmitting,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              fullWidth
                              helperText={
                                touched.description && errors.description
                              }
                              label="Description"
                              margin="normal"
                              name="description"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.description}
                              variant="outlined"
                            />
                            <TextField
                              fullWidth
                              label="Select Class"
                              name="class"
                              onChange={handleChange}
                              required
                              select
                              onBlur={handleBlur}
                              margin="normal"
                              SelectProps={{ native: true }}
                              value={values.class}
                              variant="outlined"
                            >
                              {CLASS_NAMES &&
                                CLASS_NAMES.map((option) => (
                                  <option key={option.id} value={option.label}>
                                    {option.label}
                                  </option>
                                ))}
                            </TextField>
                            <TextField
                              error={Boolean(touched.colour && errors.colour)}
                              fullWidth
                              helperText={touched.colour && errors.colour}
                              label="Colour"
                              margin="normal"
                              name="colour"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.colour}
                              variant="outlined"
                            />
                            <TextField
                              error={Boolean(
                                touched.license_plate && errors.license_plate
                              )}
                              fullWidth
                              helperText={
                                touched.license_plate && errors.license_plate
                              }
                              label="License Plate"
                              margin="normal"
                              name="license_plate"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.license_plate}
                              variant="outlined"
                            />
                            {currentVehicle && (
                              <div>
                                <img
                                  style={{
                                    height: 100,
                                    marginTop: 10,
                                  }}
                                  src={
                                    currentVehicle[0].url_image
                                      ? currentVehicle[0].url_image
                                      : ImageVehicle
                                  }
                                  alt="image_vehicle"
                                />
                              </div>
                            )}
                            <Box paddingY={3}>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                  >
                                    UPDATE
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    size="large"
                                    fullWidth
                                    onClick={goBack}
                                    variant="contained"
                                  >
                                    GO BACK
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </form>
                        )}
                      </Formik>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EditVehicle;
