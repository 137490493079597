import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ComplaintsListResults from 'src/components/complaints/ComplaintsListResults';
import ComplaintsToolBar from 'src/components/complaints/ComplaintsToolBar';
import { api } from 'src/service';

const Complaints = () => {
  const [complaints, setComplaints] = useState();
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [pagerInfo, setPagerInfo] = useState({
    limit: 10,
    page: 1,
    totalSize: 0,
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api
          .post('/backoffice/complaints/filter', {
            page_size: pagerInfo.limit,
            current_page: pagerInfo.page,
            search: searchStr,
          })
          .then((res) => {
            setComplaints(res.data.data.data);
            setPagerInfo({
              limit: res.data.data.page_size,
              page: res.data.data.current_page,
              totalSize: res.data.data.total_size,
            });
          });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [searchStr, pagerInfo.page, pagerInfo.limit]);

  const handleSearchStr = (str) => {
    setSearchStr(str);
  };

  const handlePagerInfo = (info) => {
    setPagerInfo(info);
  };

  return (
    <>
      <Helmet>
        <title>Washer - Complaints</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ComplaintsToolBar handleSearchStr={handleSearchStr} />
          <Box sx={{ pt: 3 }}>
            <ComplaintsListResults
              complaints={complaints}
              handlePagerInfo={handlePagerInfo}
              pagerInfo={pagerInfo}
              loading={loading}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Complaints;
