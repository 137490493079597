import logger from './logger';
import { MAIN_TOKEN_KEY } from '../constants';

// Set item into the localstorage.
export const set = (name, value) => {
  try {
    localStorage.setItem(name, JSON.stringify(value));
    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

// Get item from the localstorage.
export const get = (name) => {
  try {
    return localStorage.getItem(name);
  } catch (err) {
    logger.error(err);
    return false;
  }
};

// Remove item from the localstorage.
export const remove = (name) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

// Check If token exists in the localstorage.
export const loggedIn = () => {
  const token = get(MAIN_TOKEN_KEY);
  return !!token;
};
