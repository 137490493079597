import { api } from '../../service';

/**
 * Send push notification.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const sendPushNotification = (values) => async () => {
  try {
    const { data } = await api.post('/push_notifications', values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.general
      : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Send Sms notification.
 *
 * @param {object} values 
 * @returns 
 */
export const sendSmsNotification = (values) => async () => {
  try {
    const { data } = await api.post('/backoffice/sms', values);

    return data.data;
  } catch (error) {
    const message = error.response
      ? error.response.data.moreInformation.error
      : { message: error.message };
    throw new Error(message);
  }
}