import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { api } from 'src/service';
import { Helmet } from 'react-helmet';
import ExtraPaymentAddForm from 'src/components/payments/ExtraPaymentAddForm';
import ExtraPaymentUpdateForm from 'src/components/payments/ExtraPaymentUpdateForm';
import ExtraPaymentsTable from 'src/components/payments/ExtraPaymentsTable';

const ExtraPayments = () => {
  const [loading, setLoading] = useState(false);
  const [washer, setWasher] = useState(null);
  const [addForm, setAddForm] = useState(true);
  const [extraPayments, setExtraPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({
    id: null,
    description: null,
    amount: null,
  });
  const [pagerInfo, setPagerInfo] = useState({
    limit: 5,
    page: 1,
    totalSize: 0,
  });
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api.get(`/extra_payment/${params.washerId}`).then((res) => {
          setExtraPayments(res.data.data);
          setPagerInfo({
            limit: 5,
            page: 1,
            totalSize: res.data.data.length,
          });
        });

        await api.get(`/washer_info/${params.washerId}`).then((res) => {
          setWasher(res.data.data[0]);
        });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Washer - Extra Payments</title>
      </Helmet>

      <Container maxWidth={false}>
        <Box paddingTop={3}>
          <Card>
            <CardHeader title="Payment Details" />
          </Card>
        </Box>
        <Box paddingTop={3}>
          {loading && <CircularProgress />}
          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {washer && addForm && (
                  <ExtraPaymentAddForm
                    washer={washer}
                    setExtraPayments={setExtraPayments}
                  />
                )}
                {washer && !addForm && (
                  <ExtraPaymentUpdateForm
                    washer={washer}
                    selectedPayment={selectedPayment}
                    setExtraPayments={setExtraPayments}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={8}>
                <ExtraPaymentsTable
                  payments={extraPayments}
                  setExtraPayments={setExtraPayments}
                  addForm={addForm}
                  setAddForm={setAddForm}
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                  pagerInfo={pagerInfo}
                />
              </Grid>
              {!addForm && (
                <Grid item xs={12} md={2} sm={4} mt={4}>
                  <Button
                    color="primary"
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={() => setAddForm(true)}
                  >
                    Add Extra Payments
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ExtraPayments;
