import { SET_WASHER_LIST, SET_WASHER_INFO } from './constant';

const DEFAULT_STATE = {
  list: null,
  info: null
};

const washersReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_WASHER_LIST:
      return { ...state, list: action.payload };
    case SET_WASHER_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default washersReducer;
