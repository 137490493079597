import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { getJobByID, updateJobByID } from 'src/redux/jobs/action';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

const JOB_TYPES = [
  {
    value: 'Waiting',
    label: 'Waiting',
  },
  {
    value: 'Finished',
    label: 'Finished',
  },
];

const AddJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [job, setJobs] = useState();
  const jobID = location.state;

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getJobByID(jobID));
      setJobs(data[0]);
    };
    fetchData();
  }, [jobID]);

  const goBack = () => {
    navigate('/app/jobs');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Edit Job" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    service_name: job ? job.service_name : '',
                    washer_name: job ? job.washer_name : '',
                    price: job ? job.price : '',
                    commission: job ? job.commission : '',
                    status: job ? job.status : '',
                    vehicle_category: job ? job.vehicle_category : '',
                  }}
                  validationSchema={Yup.object().shape({
                    service_name: Yup.string()
                      .max(255)
                      .required('Name of the Service is required'),
                    washer_name: Yup.string()
                      .max(255)
                      .required('washer_name is required'),
                    price: Yup.number().required('Price is required'),
                    commission: Yup.number().required('commission is required'),
                    status: Yup.string().required('Status is required'),
                    vehicle_category: Yup.string().required(
                      'Vehicle Category is required'
                    ),
                  })}
                  onSubmit={async (values) => {
                    await dispatch(updateJobByID(values, jobID));
                    navigate('/app/jobs', { replace: true });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(
                          touched.service_name && errors.service_name
                        )}
                        fullWidth
                        helperText={touched.service_name && errors.service_name}
                        label="Enter Name of the Service"
                        margin="normal"
                        name="service_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.service_name}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(
                          touched.washer_name && errors.washer_name
                        )}
                        fullWidth
                        helperText={touched.washer_name && errors.washer_name}
                        label="Enter Washer Name"
                        margin="normal"
                        name="washer_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.washer_name}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.price && errors.price)}
                        fullWidth
                        helperText={touched.price && errors.price}
                        label="Enter Price (in $)"
                        margin="normal"
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.price}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.commission && errors.commission)}
                        fullWidth
                        helperText={touched.commission && errors.commission}
                        label="Enter Commision"
                        margin="normal"
                        name="commission"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.commission}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Select vehicle category"
                        name="vehicle_category"
                        margin="normal"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.vehicle_category}
                        variant="outlined"
                      >
                        {JOB_TYPES &&
                          JOB_TYPES.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </TextField>
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              type="submit"
                              variant="contained"
                            >
                              UPDATE
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AddJob;
