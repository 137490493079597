import { SET_SETTLEMENT_ID } from './constant';

const DEFAULT_STATE = {
  settlementID: null
};

const settlementReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SETTLEMENT_ID:
      return { ...state, settlementID: action.payload };
    default:
      return state;
  }
};

export default settlementReducer;
