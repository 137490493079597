import React, { Component } from 'react';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { Avatar, Button } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import customImageInputStyle from './CustomImageInputStyle';

class CustomImageInput extends Component {
  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.state = {
      file: undefined,
      imagePreviewUrl: undefined,
    };
  }

  handleImageChange(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);
      // eslint-disable-next-line react/prop-types
      this.props.setFieldValue(this.props.field.name, file);
      this.getBase64(file, (result) => {
        // eslint-disable-next-line react/prop-types
        this.props.handleBase64(result);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getBase64(file, cb) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // eslint-disable-next-line func-names
    reader.onload = function () {
      cb(reader.result);
    };
    // eslint-disable-next-line func-names
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  showFileUpload() {
    if (this.fileUpload) {
      this.fileUpload.current.click();
    }
  }

  showPreloadImage() {
    // eslint-disable-next-line react/prop-types
    const { errorMessage, classes } = this.props;
    const { file, imagePreviewUrl } = this.state;

    let comp = null;

    if (errorMessage) {
      comp = <Icon style={{ fontSize: 36 }}>error_outline</Icon>;
    } else if (file) {
      comp = (
        // eslint-disable-next-line react/prop-types
        <img className={classes.avatarThumb} src={imagePreviewUrl} alt="..." />
      );
    } else {
      comp = <Icon style={{ fontSize: 36 }} />;
    }
    return comp;
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { errorMessage, title, classes } = this.props;
    // eslint-disable-next-line react/prop-types
    const { name } = this.props.field;

    const avatarStyle = classnames(
      // eslint-disable-next-line react/prop-types
      classes.bigAvatar,
      // eslint-disable-next-line react/prop-types
      this.state.file ? [classes.whiteBack] : [classes.primaryBack],
      // eslint-disable-next-line react/prop-types
      { [classes.errorBack]: errorMessage }
    );

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            // eslint-disable-next-line react/prop-types
            className={classes.hidden}
            id={name}
            name={name}
            type="file"
            onChange={this.handleImageChange}
            ref={this.fileUpload}
            accept="image/svg+xml"
          />
          {/* eslint-disable-next-line react/prop-types */}
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
          <Button
            width={60}
            onClick={this.showFileUpload}
            variant="contained"
            style={{ marginLeft: 20 }}
          >
            Open
          </Button>
          {errorMessage ? (
            <Typography variant="caption" color="error">
              {errorMessage}
            </Typography>
          ) : null}
        </div>

        {this.state.file && (
          <Avatar
            style={{ marginTop: 20 }}
            className={avatarStyle}
            onClick={this.showFileUpload}
          >
            {this.showPreloadImage()}
          </Avatar>
        )}
      </>
    );
  }
}

export default withStyles(customImageInputStyle)(CustomImageInput);
