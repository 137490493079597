import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToast } from 'src/contexts/toast';

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DelteIcon from '@material-ui/icons/Delete';
import ResetIcon from '@material-ui/icons/RestartAlt';
import { resetPasswordAdmin } from 'src/redux/admins/action';


import EnhancedTableHead from '../EnhancedTableHead';


const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
    sortable: true,
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
    sortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email Address',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}




const UserListResults = ({
  admins,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();


  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToEdit = (id) => {
    navigate(`/app/admins/edit/${id}`, { state: id });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleReset = async (email) => {
    await dispatch(resetPasswordAdmin(email));
    showToast('Reset password email sent.');
    console.log(email);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                admins &&
                stableSort(admins, getComparator(order, orderBy)).map(
                  (row, index) => (
                    <TableRow hover key={row.id}>
                      <TableCell>
                        {(pagerInfo.page - 1) * pagerInfo.limit + index + 1}
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {row.firstname}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{row.lastname}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => goToEdit(row.id)}
                        />
                        <ResetIcon
                          style={{ cursor: 'pointer'}}
                          onClick={() => handleReset({email: row.email})}
                        />
                        <DelteIcon
                          style={{ cursor: 'pointer', color: 'indianred' }}
                          onClick={() => handleRow(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!loading && !admins && (
                <Typography className={classes.loader}>No Data</Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

UserListResults.propTypes = {
  admins: PropTypes.array.isRequired,
  handleRow: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default UserListResults;
