import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Box, Drawer, List, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {
  Users as AdminIcon,
  CloudLightning as ServiceIcon,
  Calendar as JobsIcon,
  Tool as SettlementIcon,
  Sliders as UtilityIcon,
  Settings as SettingsIcon,
  Aperture as VehiclesIcon,
  Globe as TranslationIcon,
} from 'react-feather';
import NavItem from './NavItem';

const appMenuItems = [
  {
    icon: AdminIcon,
    title: 'Users',
    items: [
      { title: 'Admin', href: '/app/admins' },
      { title: 'Clients', href: '/app/clients' },
      { title: 'Washers', href: '/app/washers' },
    ],
  },
  {
    icon: ServiceIcon,
    title: 'Operation',
    items: [
      { title: 'Services', href: '/app/services' },
      { title: 'Settings', href: '/app/services_settings' },
    ],
  },
  {
    icon: JobsIcon,
    title: 'Jobs',
    items: [
      { title: 'Washes', href: '/app/jobs' },
      { title: 'Reviews', href: '/app/reviews' },
      { title: 'Cancelations', href: '/app/cancelations' },
      { title: 'Complaints', href: '/app/complaints' },
    ],
  },
  {
    href: '/app/vehicles',
    icon: VehiclesIcon,
    title: 'Client Vehicles',
  },
  {
    href: '/app/payments',
    icon: SettlementIcon,
    title: 'Settlement',
  },
  {
    href: '/app/utility',
    icon: UtilityIcon,
    title: 'Utility',
  },
  {
    href: '/app/translations',
    icon: TranslationIcon,
    title: 'Translations',
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
  },
];

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

/* eslint-disable react/prop-types */
const SubMenu = ({ icon, title, items }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavItem
        title={title}
        icon={icon}
        rightContent={
          <Box component="span" display="inline-block" marginLeft="auto">
            {open ? <ExpandLess size="10" /> : <ExpandMore size="10" />}
          </Box>
        }
        onClick={handleClick}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((subItem) => (
            <NavItem
              className={classes.nested}
              href={subItem.href}
              title={subItem.title}
              icon={subItem.icon}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

/* eslint-enable react/prop-types */
const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {appMenuItems.map((item) => {
            if (item.items) {
              return (
                <SubMenu
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  items={item.items}
                />
              );
            }

            return (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box display={{ lg: 'none' }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;
