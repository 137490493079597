import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CategoryList from '../../components/categories/CategoryList';
import CategoryToolBar from '../../components/categories/CategoryToolBar';
import { getCategoryList, removeCategory } from '../../redux/vehicles-category/action';

const Category = () => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [categoryID, setCategoryID] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setLoading(true);
    dispatch(getCategoryList()).then(data => {
      setCategories(data);
      setLoading(false);
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleRow = async (id) => {
    handleOpen(true);
    setCategoryID(id);
  };

  const handleRemove = async () => {
    setOpen(false);
    setLoading(true);
    await dispatch(removeCategory(categoryID));
    const data = await dispatch(getCategoryList());
    setCategories(data);
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>CATEGORY</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <CategoryToolBar />
          <Box sx={{ pt: 3 }}>
            <CategoryList categories={categories} handleRow={handleRow} loading={loading} />
          </Box>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Confirmation
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove it?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={handleRemove} color="primary" autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Category;
