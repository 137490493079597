import { SET_SERVICE_LIST, SET_SERVICE_INFO } from './constant';

const DEFAULT_STATE = {
  list: null,
  info: null
};

const servicesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_SERVICE_LIST:
      return { ...state, list: action.payload };
    case SET_SERVICE_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};

export default servicesReducer;
