import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  getGeneralSetting,
  getSmsConfiguration,
  updateGeneralSettings,
  updateSmsConfiguration,
} from 'src/redux/settings/action';
import { useToast } from 'src/contexts/toast';
import SmsConfiguration from '../components/settings/SmsConfiguration';
import GeneralSettings from '../components/settings/GeneralSettings';

const Settings = () => {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const settings2 = dispatch(getGeneralSetting());
    settings2.then((result) => {
      setSettings(result.settings[0]);
    });

    dispatch(getSmsConfiguration());
  }, []);

  const handleGeneralSetting = (values) => {
    dispatch(updateGeneralSettings(values));
    showToast('Settings updated successfully!');
  };

  const handleSmsConfiguration = (values) => {
    dispatch(updateSmsConfiguration(values));
    showToast('Settings updated successfully!');
  };

  return (
    <>
      <Helmet>
        <title>Washer - Settings</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <SmsConfiguration handleSmsConfiguration={handleSmsConfiguration} />
          <Box sx={{ pt: 3 }}>
            <GeneralSettings
              handleGeneralSetting={handleGeneralSetting}
              settings={settings}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Settings;
