import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelationsListResults from 'src/components/cancelations/CancelationsListResults';
import CancelationsToolBar from 'src/components/cancelations/CancelationsToolBar';
import { api } from 'src/service';

const Cancelations = () => {
  const [cancelations, setCancelations] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [searchStr, setSearchStr] = useState('');
  const [pagerInfo, setPagerInfo] = useState({
    limit: 10,
    page: 1,
    totalSize: 0,
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api
          .post('/backoffice/cancelations/filter', {
            page_size: pagerInfo.limit,
            current_page: pagerInfo.page,
            search: searchStr,
          })
          .then((res) => {
            setCancelations(res.data.data.data);
            setPagerInfo({
              limit: res.data.data.page_size,
              page: res.data.data.current_page,
              totalSize: res.data.data.total_size,
            });
          });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [searchStr, pagerInfo.page, pagerInfo.limit]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchStr = (str) => {
    setSearchStr(str);
  };

  const handlePagerInfo = (info) => {
    setPagerInfo(info);
  };

  return (
    <>
      <Helmet>
        <title>Washer - Cancelations</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <CancelationsToolBar handleSearchStr={handleSearchStr} />
          <Box sx={{ pt: 3 }}>
            <CancelationsListResults
              cancelations={cancelations}
              handlePagerInfo={handlePagerInfo}
              pagerInfo={pagerInfo}
              loading={loading}
            />
          </Box>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to remove it?
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Cancelations;
