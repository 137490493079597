import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PushNotification from '../../components/utility/PushNotification';
import SmsNotification from '../../components/utility/SmsNotification';
import {
  sendPushNotification,
  sendSmsNotification,
} from '../../redux/utility/action';

const Utility = () => {
  const dispatch = useDispatch();

  const handlePushNotification = (values) => {
    dispatch(sendPushNotification(values));
  };

  const handleSmsNotification = (values) => {
    dispatch(sendSmsNotification(values));
  };
  return (
    <>
      <Helmet>
        <title>Washer - Utility</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <PushNotification handlePushNotification={handlePushNotification} />
          <Box sx={{ pt: 3 }}>
            <SmsNotification handleSmsNotification={handleSmsNotification} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Utility;
