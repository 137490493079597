import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { getMakesList } from 'src/redux/vehicles-make/action';
import { getModelsList } from 'src/redux/vehicles-model/action';
import { getCategoryList } from 'src/redux/vehicles-category/action';
import { createVehicleType } from 'src/redux/vehicle-types/action';
import { useToast } from 'src/contexts/toast';

const AddVehicleType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [makes, setMakes] = useState();
  const [models, setModels] = useState();
  const [categories, setCategories] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const dataMakes = await dispatch(getMakesList());
      setMakes(dataMakes);
      const dataModels = await dispatch(getModelsList());
      setModels(dataModels);
      const dataCategories = await dispatch(getCategoryList());
      setCategories(dataCategories);
    };
    fetchData();
  }, []);

  const goBack = () => {
    navigate('/app/vehicle-types');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Add Vehicle Type" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    make_id: makes ? makes[0].id : '',
                    model_id: models ? models[0].model_id : '',
                    category_id: categories ? categories[0].id : '',
                  }}
                  validationSchema={Yup.object().shape({
                    make_id: Yup.string()
                      .max(255)
                      .required('Name of the Service is required'),
                    model_id: Yup.string()
                      .max(255)
                      .required('model is required'),
                    category_id: Yup.number().required('category is required'),
                  })}
                  onSubmit={async (values) => {
                    try {
                      await dispatch(createVehicleType(values));
                      showToast('Vehicle type is created successfully!');
                      navigate('/app/vehicle-types', { replace: true });
                    } catch (error) {
                      showToast(error.message, 'warning');
                    }
                  }}
                >
                  {({
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        fullWidth
                        label="Select Make"
                        name="make_id"
                        onChange={handleChange}
                        required
                        select
                        margin="normal"
                        SelectProps={{ native: true }}
                        value={values.make_id}
                        variant="outlined"
                      >
                        {makes &&
                          makes.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.make}
                            </option>
                          ))}
                      </TextField>
                      <TextField
                        fullWidth
                        label="Select Model"
                        name="model_id"
                        onChange={handleChange}
                        required
                        select
                        margin="normal"
                        SelectProps={{ native: true }}
                        value={values.model_id}
                        variant="outlined"
                      >
                        {models &&
                          models
                            .filter(
                              (model) =>
                                String(model.make_id) === values.make_id
                            )
                            .map((option) => (
                              <option
                                key={option.model_id}
                                value={option.model_id}
                              >
                                {option.model}
                              </option>
                            ))}
                      </TextField>
                      <TextField
                        fullWidth
                        label="Select Category"
                        name="category_id"
                        onChange={handleChange}
                        required
                        select
                        margin="normal"
                        SelectProps={{ native: true }}
                        value={values.category_id}
                        variant="outlined"
                      >
                        {categories &&
                          categories.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.vehicle_category}
                            </option>
                          ))}
                      </TextField>
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              type="submit"
                              variant="contained"
                            >
                              ADD
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AddVehicleType;
