import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const ToastContext = createContext({});
const useToast = () => useContext(ToastContext);

const ToastProvider = ({ children }) => {
  const [toastConfig, setToastConfig] = useState({
    vertical: 'top',
    horizontal: 'center',
    elevation: 6,
    variant: 'filled',
    autoHideDuration: 6000,
  });

  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleClose = () => {
    setToast({
      open: false,
      message: '',
      severity: 'success',
    });
  };

  const setOptions = useCallback(
    ({
      vertical = 'bottom',
      horizontal = 'right',
      elevation = 6,
      variant = 'filled',
      autoHideDuration = 6000,
    }) => {
      setToastConfig({
        vertical,
        horizontal,
        elevation,
        variant,
        autoHideDuration,
      });
    },
    []
  );

  const showToast = useCallback((message, type = 'success') => {
    setToast({
      open: true,
      severity: type,
      message,
    });
  }, []);

  return (
    <>
      <ToastContext.Provider value={{ showToast, setOptions }}>
        {children}
      </ToastContext.Provider>
      <Snackbar
        anchorOrigin={{
          vertical: toastConfig.vertical,
          horizontal: toastConfig.horizontal,
        }}
        autoHideDuration={toastConfig.autoHideDuration}
        open={toast.open}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={toastConfig.elevation}
          onClose={handleClose}
          severity={toast.severity}
        >
          {toast.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};

export { ToastProvider, useToast };
