import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Divider,
  CardContent,
} from '@material-ui/core';

const selectNotificationTo = [
  {
    value: 'allWashers',
    label: 'All Washers',
  },
  {
    value: 'activeWashers',
    label: 'Online Washers',
  },
  {
    value: 'allUsers',
    label: 'All Users',
  },
  {
    value: 'inactiveUsers',
    label: 'Inactive Users',
  },
];

const PushNotification = (props) => (
  <Card>
    <CardHeader title="Send Push Notification" />
    <Divider />
    <CardContent>
      <Formik
        initialValues={{
          send_to: selectNotificationTo[0].value,
          message: '',
        }}
        validationSchema={Yup.object().shape({
          send_to: Yup.string()
            .max(255)
            .required('Select Notification To is required'),
          message: Yup.string().max(255).required('Message is required'),
        })}
        onSubmit={async (values) => {
          props.handlePushNotification(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Select Notification To"
              name="send_to"
              onChange={handleChange}
              required
              select
              SelectProps={{ native: true }}
              value={values.send_to}
              variant="outlined"
            >
              {selectNotificationTo &&
                selectNotificationTo.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </TextField>
            <TextField
              error={Boolean(touched.message && errors.message)}
              fullWidth
              helperText={touched.message && errors.message}
              label="Enter a message"
              margin="normal"
              name="message"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.message}
              variant="outlined"
            />
            <Box paddingY={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    SEND PUSH NOTIFICATION
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </CardContent>
  </Card>
);

PushNotification.propTypes = {
  handlePushNotification: PropTypes.func,
};

export default PushNotification;
