import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  {
    id: 'transaction_id',
    numeric: false,
    disablePadding: false,
    label: 'Transaction ID',
    sortable: true,
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: true,
  },
  {
    id: 'payment_amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    sortable: true,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    sortable: true,
  },
  {
    id: 'balance',
    numeric: false,
    disablePadding: false,
    label: 'Balance',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Creation Date',
    sortable: true,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SettlementTransferTable = ({
  payments,
  setPayments,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const applyPagination = (Payments, Page, Limit) =>
    Payments.slice(Page * Limit, Page * Limit + Limit);

  const paginatedPayments = applyPagination(payments, page, limit);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody className={classes.tableBody}>
                {loading && <CircularProgress className={classes.loader} />}
                {!loading &&
                  paginatedPayments &&
                  stableSort(
                    paginatedPayments,
                    getComparator(order, orderBy)
                  ).map((payment) => (
                    <TableRow hover key={payment.transaction_id}>
                      <TableCell>{payment.transaction_id}</TableCell>
                      <TableCell>
                        {`${payment.firstname} ${payment.lastname}`}
                      </TableCell>
                      <TableCell>{payment.description}</TableCell>
                      <TableCell>{payment.payment_amount}</TableCell>
                      <TableCell>{payment.type}</TableCell>
                      <TableCell>{payment.balance}</TableCell>
                      <TableCell>{payment.status}</TableCell>
                      <TableCell>{payment.date}</TableCell>
                    </TableRow>
                  ))}
                {!loading && !paginatedPayments.length && (
                  <p className={classes.loader}>
                    <Typography>No Data</Typography>
                  </p>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={payments.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

SettlementTransferTable.propTypes = {
  payments: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
  setSelectedPayment: PropTypes.func,
  setAddForm: PropTypes.func,
  setPayments: PropTypes.func,
};

export default SettlementTransferTable;
