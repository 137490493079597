import { SET_VEHICLES_CATEGORY_LIST, SET_VEHICLES_CATEGORY_INFO } from './constant';
import { api } from '../../service';

/**
 * Get category list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getCategoryList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/vehicle_category');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_VEHICLES_CATEGORY_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get category by ID.
 *
 * @param {string} categoryID
 * @returns
 */
export const getCategoryByID = (categoryID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/vehicle_category/${categoryID}`);
    if (data) {
      dispatch({ type: SET_VEHICLES_CATEGORY_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update category by ID.
 *
 * @param {object} values
 * @param {string} categoryID
 * @returns
 */
export const updateCategoryByID = (values, categoryID) => async () => {
  try {
    const { data } = await api.patch(`/vehicle_category/${categoryID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add category.
 *
 * @param {object} values
 * @returns
 */
export const createCategory = (values) => async () => {
  try {
    const { data } = await api.post('/vehicle_category', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove category by ID.
 *
 * @param {string} categoryID
 * @returns
 */
export const removeCategory = (categoryID) => async () => {
  try {
    const { data } = await api.delete(`/vehicle_category/${categoryID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
