import { SET_CLIENT_LIST, SET_CLIENT_INFO } from './constant';
import { api } from '../../service';

/**
 * Get clients list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getClientsList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/backoffice/client');

    if (data) {
      // Dispatch User Info.
      dispatch({ type: SET_CLIENT_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get client by ID.
 *
 * @param {string} clientID
 * @returns
 */
export const getClientByID = (clientID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/backoffice/client/${clientID}`);
    if (data) {
      dispatch({ type: SET_CLIENT_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update client by ID.
 *
 * @param {object} values
 * @param {string} clientID
 * @returns
 */
export const updateClientByID = (values, clientID) => async () => {
  try {
    const { data } = await api.patch(`/backoffice/client/${clientID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add client.
 *
 * @param {object} values
 * @returns
 */
export const createClient = (values) => async () => {
  try {
    const { data } = await api.post('/client', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove client by ID.
 *
 * @param {string} clientID
 * @returns
 */
export const removeClient = (clientID) => async () => {
  try {
    const { data } = await api.delete(`/backoffice/client/${clientID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
