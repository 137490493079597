import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  TextField,
  Grid,
} from '@material-ui/core';
import { createAdmin } from 'src/redux/admins/action';
import 'react-phone-number-input/style.css';
import { useToast } from 'src/contexts/toast';

const AddAdminUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const goBack = () => {
    navigate('/app/admins');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Add Admin" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                <Grid container spacing={2} justifyContent="flex-start">
                  <Grid item xs={12} sm={12}>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        email: '',
                        firstname: '',
                        lastname: '',
                      }}
                      validationSchema={Yup.object().shape({
                        firstname: Yup.string()
                          .max(255)
                          .required('First name is required'),
                        lastname: Yup.string()
                          .max(255)
                          .required('Last name is required'),
                        email: Yup.string()
                          .email('Must be a valid email')
                          .max(255)
                          .required('Email is required'),
                      })}
                      onSubmit={async (values) => {
                        try {
                          const data = values;

                          await dispatch(createAdmin(data));
                          showToast('Admin User is created successfully!');
                          navigate('/app/admins', { replace: true });
                        } catch (error) {
                          showToast(error.message, 'warning');
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            label="First name"
                            margin="normal"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            label="Last name"
                            margin="normal"
                            name="lastname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email Address"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={values.email}
                            variant="outlined"
                          />
                          <Box paddingY={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                >
                                  ADD
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  fullWidth
                                  onClick={goBack}
                                  variant="contained"
                                >
                                  GO BACK
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddAdminUser;
