import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { createWasher } from 'src/redux/washers/action';

const CURRENT_STATUS = [
  {
    value: 'on',
    label: 'On',
  },
  {
    value: 'off',
    label: 'Off',
  },
];

const AddWasher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/app/washers');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Add Washer" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6}>
                <Formik
                  initialValues={{
                    email: '',
                    firstname: '',
                    lastname: '',
                    phonenumber: '',
                    current_status: 'on',
                  }}
                  validationSchema={Yup.object().shape({
                    firstname: Yup.string()
                      .max(255)
                      .required('First name is required'),
                    lastname: Yup.string()
                      .max(255)
                      .required('Last name is required'),
                    email: Yup.string()
                      .email('Must be a valid email')
                      .max(255)
                      .required('Email is required'),
                    phonenumber: Yup.string().required(
                      'Phone number is required'
                    ),
                    current_status: Yup.string().required(
                      'Client status is required'
                    ),
                  })}
                  onSubmit={async (values) => {
                    await dispatch(createWasher(values));
                    navigate('/app/washers', { replace: true });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(touched.firstname && errors.firstname)}
                        fullWidth
                        helperText={touched.firstname && errors.firstname}
                        label="First name"
                        margin="normal"
                        name="firstname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstname}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.lastname && errors.lastname)}
                        fullWidth
                        helperText={touched.lastname && errors.lastname}
                        label="Last name"
                        margin="normal"
                        name="lastname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(
                          touched.phonenumber && errors.phonenumber
                        )}
                        fullWidth
                        helperText={touched.phonenumber && errors.phonenumber}
                        margin="normal"
                        label="Phone Number"
                        name="phonenumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phonenumber}
                        variant="outlined"
                      />
                      <TextField
                        fullWidth
                        label="Current Status"
                        name="current_status"
                        onChange={handleChange}
                        required
                        select
                        SelectProps={{ native: true }}
                        value={values.current_status}
                        variant="outlined"
                      >
                        {CURRENT_STATUS.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              type="submit"
                              variant="contained"
                            >
                              ADD
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AddWasher;
