import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { api } from 'src/service';
import { useParams } from 'react-router-dom';
import ClientFailedPaymentListResult from '../../components/clients/ClientFailedPaymentListResult';

const ClientFailedPaymet = () => {
  const [loading, setLoading] = useState(false);
  const [failedPayments, setFailedPayments] = useState([]);
  const [pagerInfo, setPagerInfo] = useState({
    limit: 10,
    page: 1,
    totalSize: 0,
  });
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api
          .post(`/client/failed_payments/${params.clientId}`, {
            page_size: pagerInfo.limit,
            current_page: pagerInfo.page,
          })
          .then((res) => {
            setFailedPayments(res.data.data.data);
            setPagerInfo({
              limit: res.data.data.page_size,
              page: res.data.data.current_page,
              totalSize: res.data.data.total_size,
            });
          });
      } catch (error) {
        throw new Error();
      }
      setLoading(false);
    };

    fetchData();
  }, [pagerInfo.page, pagerInfo.limit]);

  const handlePagerInfo = (info) => {
    setPagerInfo(info);
  };

  return (
    <>
      <Helmet>
        <title>Washer - Failed Payments</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <ClientFailedPaymentListResult
              failedPayments={failedPayments}
              loading={loading}
              pagerInfo={pagerInfo}
              handlePagerInfo={handlePagerInfo}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ClientFailedPaymet;
