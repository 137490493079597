import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import jobsReducer from './jobs/reducer';
import clientsReducer from './clients/reducer';
import adminsReducer from './admins/reducer';
import washersReducer from './washers/reducer';
import servicesReducer from './services/reducer';
import categoryReducer from './vehicles-category/reducer';
import modelReducer from './vehicles-model/reducer';
import makeReducer from './vehicles-make/reducer';
import vehiclesReducer from './vehicle-types/reducer';
import settingsReducer from './settings/reducer';
import settlementReducer from './settlement/reducer';

export default combineReducers({
  auth: authReducer,
  jobs: jobsReducer,
  clients: clientsReducer,
  admins: adminsReducer,
  washers: washersReducer,
  services: servicesReducer,
  category: categoryReducer,
  model: modelReducer,
  make: makeReducer,
  vehicles: vehiclesReducer,
  settings: settingsReducer,
  settlement: settlementReducer
});
