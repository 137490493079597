import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  FormHelperText,
  TextField,
  InputAdornment,
  Autocomplete,
} from '@material-ui/core';
import { api } from 'src/service';
import { useToast } from 'src/contexts/toast';

const TRANSFER_TYPES = [
  {
    id: 1,
    label: 'IBAN',
  },
  {
    id: 2,
    label: 'MBWAY',
  },
];

const SettlementTransferAddForm = (props) => {
  const params = useParams();
  const { showToast } = useToast();
  const { washer, setPayments } = props;
  const [type, setType] = useState('');

  return (
    <Container maxWidth={false} style={{ paddingLeft: '0px' }}>
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    description: '',
                    payment_amount: '',
                    type: '',
                  }}
                  validationSchema={Yup.object().shape({
                    description: Yup.string()
                      .max(255)
                      .required('Description is required'),
                    payment_amount: Yup.number().required('Amount is required'),
                  })}
                  onSubmit={async (values, { resetForm }) => {
                    try {
                      const payload = {
                        washer_id: params.washerId,
                        description: values.description,
                        payment_amount: values.payment_amount,
                        status: 'paid',
                        type: type.label,
                      };

                      await api.post(`/paymentwasher`, payload).then((res) => {
                        setPayments(res.data.data);
                      });
                      resetForm();
                      showToast('Transfer created successfully!');
                    } catch (error) {
                      showToast(error.message, 'warning');
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            label="Washer first name"
                            margin="normal"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.firstname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            label="Washer last name"
                            margin="normal"
                            name="lastname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.lastname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Washer email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={washer.email}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(
                              touched.bank_iban && errors.bank_iban
                            )}
                            fullWidth
                            helperText={touched.bank_iban && errors.bank_iban}
                            label="Washer IBAN"
                            margin="normal"
                            name="bank_iban"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.bank_iban}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={2}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label="Transfer Description"
                            margin="normal"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <TextField
                            error={Boolean(
                              touched.payment_amount && errors.payment_amount
                            )}
                            fullWidth
                            helperText={
                              touched.payment_amount && errors.payment_amount
                            }
                            type="number"
                            label="Transfer Amount"
                            margin="normal"
                            name="payment_amount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.payment_amount}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <Autocomplete
                            id="combo-box-tenant"
                            options={TRANSFER_TYPES}
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                              if (value) {
                                setType(value);
                              }
                            }}
                            onBlur={handleBlur}
                            renderInput={(params2) => (
                              <TextField
                                {...params2}
                                error={Boolean(touched.type && errors.type)}
                                required
                                helperText={touched.type && errors.type}
                                label="Transfer Type"
                                fullWidth
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <Button
                            color="primary"
                            size="large"
                            fullWidth
                            variant="contained"
                            type="submit"
                          >
                            Add Payment
                          </Button>
                        </Grid>
                      </Grid>

                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

SettlementTransferAddForm.propTypes = {
  washer: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  bank_iban: PropTypes.string,
  setPayments: PropTypes.func,
};

export default SettlementTransferAddForm;
