import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  FormHelperText,
  TextField
} from '@material-ui/core';
import { createMake } from 'src/redux/vehicles-make/action';
import { useToast } from 'src/contexts/toast';

const AddMake = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const goBack = () => {
    navigate('/app/makes');
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Card>
          <CardHeader title="Add Make" />
        </Card>
      </Box>
      <Box paddingTop={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={6}>
                <Formik
                  initialValues={{
                    make: ''
                  }}
                  validationSchema={Yup.object().shape({
                    make: Yup.string()
                      .max(255)
                      .required('make is required')
                  })}
                  onSubmit={async (values) => {
                    try {
                      await dispatch(createMake(values));
                      showToast("Make is created successfully!");
                      navigate('/app/makes', { replace: true });
                    } catch (error) {
                      showToast(error.message, "warning");
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        error={Boolean(
                          touched.make && errors.make
                        )}
                        fullWidth
                        helperText={touched.make && errors.make}
                        label="Enter Make"
                        margin="normal"
                        name="make"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.make}
                        variant="outlined"
                      />
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              type="submit"
                              variant="contained"
                            >
                              ADD
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AddMake;
