const concelhos = [
  {
    designacao: 'Águeda',
  },
  {
    designacao: 'Albergaria-a-Velha',
  },
  {
    designacao: 'Anadia',
  },
  {
    designacao: 'Aveiro',
  },
  {
    designacao: 'Castelo de Paiva',
  },
  {
    designacao: 'Espinho',
  },
  {
    designacao: 'Estarreja',
  },
  {
    designacao: 'Santa Maria da Feira',
  },
  {
    designacao: 'Ílhavo',
  },
  {
    designacao: 'Mealhada',
  },
  {
    designacao: 'Murtosa',
  },
  {
    designacao: 'Oliveira de Azeméis',
  },
  {
    designacao: 'Oliveira do Bairro',
  },
  {
    designacao: 'Ovar',
  },
  {
    designacao: 'São João da Madeira',
  },
  {
    designacao: 'Sever do Vouga',
  },
  {
    designacao: 'Vagos',
  },
  {
    designacao: 'Vale de Cambra',
  },
  {
    designacao: 'Aljustrel',
  },
  {
    designacao: 'Almodôvar',
  },
  {
    designacao: 'Alvito',
  },
  {
    designacao: 'Barrancos',
  },
  {
    designacao: 'Beja',
  },
  {
    designacao: 'Castro Verde',
  },
  {
    designacao: 'Cuba',
  },
  {
    designacao: 'Ferreira do Alentejo',
  },
  {
    designacao: 'Mértola',
  },
  {
    designacao: 'Moura',
  },
  {
    designacao: 'Odemira',
  },
  {
    designacao: 'Ourique',
  },
  {
    designacao: 'Serpa',
  },
  {
    designacao: 'Vidigueira',
  },
  {
    designacao: 'Amares',
  },
  {
    designacao: 'Barcelos',
  },
  {
    designacao: 'Braga',
  },
  {
    designacao: 'Cabeceiras de Basto',
  },
  {
    designacao: 'Celorico de Basto',
  },
  {
    designacao: 'Esposende',
  },
  {
    designacao: 'Fafe',
  },
  {
    designacao: 'Guimarães',
  },
  {
    designacao: 'Póvoa de Lanhoso',
  },
  {
    designacao: 'Terras de Bouro',
  },
  {
    designacao: 'Vieira do Minho',
  },
  {
    designacao: 'Vila Nova de Famalicão',
  },
  {
    designacao: 'Vila Verde',
  },
  {
    designacao: 'Vizela',
  },
  {
    designacao: 'Alfândega da Fé',
  },
  {
    designacao: 'Bragança',
  },
  {
    designacao: 'Carrazeda de Ansiães',
  },
  {
    designacao: 'Freixo de Espada à Cinta',
  },
  {
    designacao: 'Macedo de Cavaleiros',
  },
  {
    designacao: 'Miranda do Douro',
  },
  {
    designacao: 'Mirandela',
  },
  {
    designacao: 'Mogadouro',
  },
  {
    designacao: 'Torre de Moncorvo',
  },
  {
    designacao: 'Vila Flor',
  },
  {
    designacao: 'Vimioso',
  },
  {
    designacao: 'Vinhais',
  },
  {
    designacao: 'Belmonte',
  },
  {
    designacao: 'Castelo Branco',
  },
  {
    designacao: 'Covilhã',
  },
  {
    designacao: 'Fundão',
  },
  {
    designacao: 'Idanha-a-Nova',
  },
  {
    designacao: 'Oleiros',
  },
  {
    designacao: 'Penamacor',
  },
  {
    designacao: 'Proença-a-Nova',
  },
  {
    designacao: 'Sertã',
  },
  {
    designacao: 'Vila de Rei',
  },
  {
    designacao: 'Vila Velha de Ródão',
  },
  {
    designacao: 'Arganil',
  },
  {
    designacao: 'Cantanhede',
  },
  {
    designacao: 'Coimbra',
  },
  {
    designacao: 'Condeixa-a-Nova',
  },
  {
    designacao: 'Figueira da Foz',
  },
  {
    designacao: 'Góis',
  },
  {
    designacao: 'Lousã',
  },
  {
    designacao: 'Mira',
  },
  {
    designacao: 'Miranda do Corvo',
  },
  {
    designacao: 'Montemor-o-Velho',
  },
  {
    designacao: 'Oliveira do Hospital',
  },
  {
    designacao: 'Pampilhosa da Serra',
  },
  {
    designacao: 'Penacova',
  },
  {
    designacao: 'Penela',
  },
  {
    designacao: 'Soure',
  },
  {
    designacao: 'Tábua',
  },
  {
    designacao: 'Vila Nova de Poiares',
  },
  {
    designacao: 'Alandroal',
  },
  {
    designacao: 'Arraiolos',
  },
  {
    designacao: 'Borba',
  },
  {
    designacao: 'Estremoz',
  },
  {
    designacao: 'Évora',
  },
  {
    designacao: 'Montemor-o-Novo',
  },
  {
    designacao: 'Mora',
  },
  {
    designacao: 'Mourão',
  },
  {
    designacao: 'Portel',
  },
  {
    designacao: 'Redondo',
  },
  {
    designacao: 'Reguengos de Monsaraz',
  },
  {
    designacao: 'Vendas Novas',
  },
  {
    designacao: 'Viana do Alentejo',
  },
  {
    designacao: 'Vila Viçosa',
  },
  {
    designacao: 'Albufeira',
  },
  {
    designacao: 'Alcoutim',
  },
  {
    designacao: 'Aljezur',
  },
  {
    designacao: 'Castro Marim',
  },
  {
    designacao: 'Faro',
  },
  {
    designacao: 'Lagoa',
  },
  {
    designacao: 'Lagos',
  },
  {
    designacao: 'Loulé',
  },
  {
    designacao: 'Monchique',
  },
  {
    designacao: 'Olhão',
  },
  {
    designacao: 'Portimão',
  },
  {
    designacao: 'São Brás de Alportel',
  },
  {
    designacao: 'Silves',
  },
  {
    designacao: 'Tavira',
  },
  {
    designacao: 'Vila do Bispo',
  },
  {
    designacao: 'Vila Real de Santo António',
  },
  {
    designacao: 'Aguiar da Beira',
  },
  {
    designacao: 'Almeida',
  },
  {
    designacao: 'Celorico da Beira',
  },
  {
    designacao: 'Figueira de Castelo Rodrigo',
  },
  {
    designacao: 'Fornos de Algodres',
  },
  {
    designacao: 'Gouveia',
  },
  {
    designacao: 'Guarda',
  },
  {
    designacao: 'Manteigas',
  },
  {
    designacao: 'Mêda',
  },
  {
    designacao: 'Pinhel',
  },
  {
    designacao: 'Sabugal',
  },
  {
    designacao: 'Seia',
  },
  {
    designacao: 'Trancoso',
  },
  {
    designacao: 'Vila Nova de Foz Côa',
  },
  {
    designacao: 'Alcobaça',
  },
  {
    designacao: 'Alvaiázere',
  },
  {
    designacao: 'Ansião',
  },
  {
    designacao: 'Batalha',
  },
  {
    designacao: 'Bombarral',
  },
  {
    designacao: 'Caldas da Rainha',
  },
  {
    designacao: 'Castanheira de Pêra',
  },
  {
    designacao: 'Figueiró dos Vinhos',
  },
  {
    designacao: 'Leiria',
  },
  {
    designacao: 'Marinha Grande',
  },
  {
    designacao: 'Nazaré',
  },
  {
    designacao: 'Óbidos',
  },
  {
    designacao: 'Pedrógão Grande',
  },
  {
    designacao: 'Peniche',
  },
  {
    designacao: 'Porto de Mós',
  },
  {
    designacao: 'Alenquer',
  },
  {
    designacao: 'Arruda dos Vinhos',
  },
  {
    designacao: 'Azambuja',
  },
  {
    designacao: 'Cadaval',
  },
  {
    designacao: 'Cascais',
  },
  {
    designacao: 'Lisboa',
  },
  {
    designacao: 'Loures',
  },
  {
    designacao: 'Lourinhã',
  },
  {
    designacao: 'Mafra',
  },
  {
    designacao: 'Oeiras',
  },
  {
    designacao: 'Sintra',
  },
  {
    designacao: 'Sobral de Monte Agraço',
  },
  {
    designacao: 'Torres Vedras',
  },
  {
    designacao: 'Vila Franca de Xira',
  },
  {
    designacao: 'Amadora',
  },
  {
    designacao: 'Odivelas',
  },
  {
    designacao: 'Alter do Chão',
  },
  {
    designacao: 'Arronches',
  },
  {
    designacao: 'Avis',
  },
  {
    designacao: 'Campo Maior',
  },
  {
    designacao: 'Castelo de Vide',
  },
  {
    designacao: 'Crato',
  },
  {
    designacao: 'Elvas',
  },
  {
    designacao: 'Fronteira',
  },
  {
    designacao: 'Gavião',
  },
  {
    designacao: 'Marvão',
  },
  {
    designacao: 'Monforte',
  },
  {
    designacao: 'Nisa',
  },
  {
    designacao: 'Ponte de Sor',
  },
  {
    designacao: 'Portalegre',
  },
  {
    designacao: 'Sousel',
  },
  {
    designacao: 'Amarante',
  },
  {
    designacao: 'Baião',
  },
  {
    designacao: 'Felgueiras',
  },
  {
    designacao: 'Gondomar',
  },
  {
    designacao: 'Lousada',
  },
  {
    designacao: 'Maia',
  },
  {
    designacao: 'Marco de Canaveses',
  },
  {
    designacao: 'Matosinhos',
  },
  {
    designacao: 'Paços de Ferreira',
  },
  {
    designacao: 'Paredes',
  },
  {
    designacao: 'Penafiel',
  },
  {
    designacao: 'Porto',
  },
  {
    designacao: 'Póvoa de Varzim',
  },
  {
    designacao: 'Santo Tirso',
  },
  {
    designacao: 'Valongo',
  },
  {
    designacao: 'Vila do Conde',
  },
  {
    designacao: 'Vila Nova de Gaia',
  },
  {
    designacao: 'Trofa',
  },
  {
    designacao: 'Abrantes',
  },
  {
    designacao: 'Alcanena',
  },
  {
    designacao: 'Almeirim',
  },
  {
    designacao: 'Alpiarça',
  },
  {
    designacao: 'Benavente',
  },
  {
    designacao: 'Cartaxo',
  },
  {
    designacao: 'Chamusca',
  },
  {
    designacao: 'Constância',
  },
  {
    designacao: 'Coruche',
  },
  {
    designacao: 'Entroncamento',
  },
  {
    designacao: 'Ferreira do Zêzere',
  },
  {
    designacao: 'Golegã',
  },
  {
    designacao: 'Mação',
  },
  {
    designacao: 'Rio Maior',
  },
  {
    designacao: 'Salvaterra de Magos',
  },
  {
    designacao: 'Santarém',
  },
  {
    designacao: 'Sardoal',
  },
  {
    designacao: 'Tomar',
  },
  {
    designacao: 'Torres Novas',
  },
  {
    designacao: 'Vila Nova da Barquinha',
  },
  {
    designacao: 'Ourém',
  },
  {
    designacao: 'Alcácer do Sal',
  },
  {
    designacao: 'Alcochete',
  },
  {
    designacao: 'Almada',
  },
  {
    designacao: 'Barreiro',
  },
  {
    designacao: 'Grândola',
  },
  {
    designacao: 'Moita',
  },
  {
    designacao: 'Montijo',
  },
  {
    designacao: 'Palmela',
  },
  {
    designacao: 'Santiago do Cacém',
  },
  {
    designacao: 'Seixal',
  },
  {
    designacao: 'Sesimbra',
  },
  {
    designacao: 'Setúbal',
  },
  {
    designacao: 'Sines',
  },
  {
    designacao: 'Arcos de Valdevez',
  },
  {
    designacao: 'Caminha',
  },
  {
    designacao: 'Melgaço',
  },
  {
    designacao: 'Monção',
  },
  {
    designacao: 'Paredes de Coura',
  },
  {
    designacao: 'Ponte da Barca',
  },
  {
    designacao: 'Ponte de Lima',
  },
  {
    designacao: 'Valença',
  },
  {
    designacao: 'Viana do Castelo',
  },
  {
    designacao: 'Vila Nova de Cerveira',
  },
  {
    designacao: 'Alijó',
  },
  {
    designacao: 'Boticas',
  },
  {
    designacao: 'Chaves',
  },
  {
    designacao: 'Mesão Frio',
  },
  {
    designacao: 'Mondim de Basto',
  },
  {
    designacao: 'Montalegre',
  },
  {
    designacao: 'Murça',
  },
  {
    designacao: 'Peso da Régua',
  },
  {
    designacao: 'Ribeira de Pena',
  },
  {
    designacao: 'Sabrosa',
  },
  {
    designacao: 'Santa Marta de Penaguião',
  },
  {
    designacao: 'Valpaços',
  },
  {
    designacao: 'Vila Pouca de Aguiar',
  },
  {
    designacao: 'Vila Real',
  },
  {
    designacao: 'Armamar',
  },
  {
    designacao: 'Carregal do Sal',
  },
  {
    designacao: 'Castro Daire',
  },
  {
    designacao: 'Cinfães',
  },
  {
    designacao: 'Lamego',
  },
  {
    designacao: 'Mangualde',
  },
  {
    designacao: 'Moimenta da Beira',
  },
  {
    designacao: 'Mortágua',
  },
  {
    designacao: 'Nelas',
  },
  {
    designacao: 'Oliveira de Frades',
  },
  {
    designacao: 'Penalva do Castelo',
  },
  {
    designacao: 'Penedono',
  },
  {
    designacao: 'Resende',
  },
  {
    designacao: 'Santa Comba Dão',
  },
  {
    designacao: 'São João da Pesqueira',
  },
  {
    designacao: 'São Pedro do Sul',
  },
  {
    designacao: 'Sátão',
  },
  {
    designacao: 'Sernancelhe',
  },
  {
    designacao: 'Tabuaço',
  },
  {
    designacao: 'Tarouca',
  },
  {
    designacao: 'Tondela',
  },
  {
    designacao: 'Vila Nova de Paiva',
  },
  {
    designacao: 'Viseu',
  },
  {
    designacao: 'Vouzela',
  },
  {
    designacao: 'Calheta',
  },
  {
    designacao: 'Câmara de Lobos',
  },
  {
    designacao: 'Funchal',
  },
  {
    designacao: 'Machico',
  },
  {
    designacao: 'Ponta do Sol',
  },
  {
    designacao: 'Porto Moniz',
  },
  {
    designacao: 'Ribeira Brava',
  },
  {
    designacao: 'Santa Cruz',
  },
  {
    designacao: 'Santana',
  },
  {
    designacao: 'São Vicente',
  },
  {
    designacao: 'Porto Santo',
  },
  {
    designacao: 'Vila do Porto',
  },
  {
    designacao: 'Lagoa',
  },
  {
    designacao: 'Ponta Delgada',
  },
  {
    designacao: 'Povoação',
  },
  {
    designacao: 'Ribeira Grande',
  },
  {
    designacao: 'Vila Franca do Campo',
  },
  {
    designacao: 'Angra do Heroísmo',
  },
  {
    designacao: 'Vila da Praia da Vitória',
  },
  {
    designacao: 'Calheta',
  },
  {
    designacao: 'Lajes do Pico',
  },
  {
    designacao: 'Madalena',
  },
  {
    designacao: 'São Roque do Pico',
  },
  {
    designacao: 'Horta',
  },
  {
    designacao: 'Lajes das Flores',
  },
  {
    designacao: 'Santa Cruz das Flores',
  },
  {
    designacao: 'Corvo',
  },
];

export default concelhos;
