import {
  REMOVE_USER_DATA,
  SET_INITIAL_USER,
} from './constant';

const DEFAULT_STATE = {
  twoFa: null,
  info: null,
  qr: null,
  initInfo: null
};

const authReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_INITIAL_USER:
      return { ...state, initInfo: action.payload };
    // After call logout action.
    case REMOVE_USER_DATA:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default authReducer;
