import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'complaint',
    numeric: false,
    disablePadding: false,
    label: 'Complaint',
    sortable: true,
  },
  {
    id: 'service_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
    sortable: true,
  },
  {
    id: 'washer_firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
    sortable: true,
  },
  {
    id: 'washer_email',
    numeric: false,
    disablePadding: false,
    label: 'Washer Email',
    sortable: true,
  },
  {
    id: 'client_firstname',
    numeric: false,
    disablePadding: false,
    label: 'User Name',
    sortable: true,
  },
  {
    id: 'client_email',
    numeric: false,
    disablePadding: false,
    label: 'User Email',
    sortable: true,
  },
  {
    id: 'status_order',
    numeric: false,
    disablePadding: false,
    label: 'Booking Status',
    sortable: true,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: 'Price',
    sortable: true,
  },
  {
    id: 'creation_date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ComplaintsListResults = ({
  complaints,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const goToEdit = (id) => {
    navigate(`/app/complaints/review/${id}`, { state: id });
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              headCells={headCells}
              onRequestSort={handleRequestSort}
            />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                complaints &&
                stableSort(complaints, getComparator(order, orderBy)).map(
                  (complaint) => (
                    <TableRow hover key={complaint.id}>
                      <TableCell>{complaint.id}</TableCell>
                      <TableCell>{complaint.complaint}</TableCell>
                      <TableCell>{complaint.service_name}</TableCell>
                      <TableCell>
                        {`${complaint.washer_firstname} ${complaint.washer_lastname}`}
                      </TableCell>
                      <TableCell>{complaint.washer_email}</TableCell>
                      <TableCell>
                        {`${complaint.client_firstname} ${complaint.client_lastname}`}
                      </TableCell>
                      <TableCell>{complaint.client_email}</TableCell>
                      <TableCell>{complaint.status_order}</TableCell>
                      <TableCell>{complaint.price}</TableCell>
                      <TableCell>{complaint.creation_date}</TableCell>
                      <TableCell>
                        <SearchIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => goToEdit(complaint.id)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              {!loading && !complaints && (
                <p className={classes.loader}>No Data</p>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ComplaintsListResults.propTypes = {
  complaints: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default ComplaintsListResults;
