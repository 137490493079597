import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { createService } from 'src/redux/services/action';
import { useToast } from 'src/contexts/toast';
import CustomImageInput from './CustomImageInput';

const AddService = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [imgBase64, setImgBase64] = useState();
  const [imgSelectedBase64, setImgSelectedBase64] = useState();

  const goBack = () => {
    navigate('/app/services');
  };

  const handleBase64 = (src) => {
    setImgBase64(src);
  };

  const handleSelectedBase64 = (src) => {
    setImgSelectedBase64(src);
  };

  return (
    <Container maxWidth={false}>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Add Service" />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={3}>
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardContent>
                <Grid container spacing={2} justifyContent="flex-start">
                  <Grid item xs={12} sm={12}>
                    <Formik
                      initialValues={{
                        acronym: '',
                        service_name: '',
                        price_class_1: '',
                        price_class_2: '',
                        description: '',
                        service_description_pt: '',
                        service_description_en: '',
                        service_order: '',
                        image: '',
                        image_selected: '',
                      }}
                      validationSchema={Yup.object().shape({
                        acronym: Yup.string()
                          .max(255)
                          .required('Acronym is required'),
                        service_name: Yup.string()
                          .max(255)
                          .required('Name of the Service is required'),
                        price_class_1: Yup.number().required(
                          'Price Class 1 is required'
                        ),
                        price_class_2: Yup.number().required(
                          'Price Class 2 is required'
                        ),
                        description: Yup.string()
                          .max(255)
                          .required('Description is required'),
                        service_description_pt: Yup.string().required(
                          'PT service Info is required'
                        ),
                        service_description_en: Yup.string().required(
                          'EN service Info is required'
                        ),
                        service_order: Yup.number().required(
                          'Service Order is required'
                        ),
                        image: Yup.mixed().required(
                          'A file is required for the image'
                        ),
                        image_selected: Yup.mixed().required(
                          'A file is required for the selected image'
                        ),
                      })}
                      onSubmit={async (values) => {
                        try {
                          // eslint-disable-next-line no-param-reassign
                          values.image = imgBase64;
                          // eslint-disable-next-line no-param-reassign
                          values.image_selected = imgSelectedBase64;

                          await dispatch(createService(values));
                          showToast('Service is created successfully!');
                          navigate('/app/services', { replace: true });
                        } catch (error) {
                          showToast(error.message, 'warning');
                        }
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <TextField
                            error={Boolean(touched.acronym && errors.acronym)}
                            fullWidth
                            helperText={touched.acronym && errors.acronym}
                            label="Acronym"
                            margin="normal"
                            name="acronym"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.acronym}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(
                              touched.service_name && errors.service_name
                            )}
                            fullWidth
                            helperText={
                              touched.service_name && errors.service_name
                            }
                            label="Service name"
                            margin="normal"
                            name="service_name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.service_name}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(
                              touched.price_class_1 && errors.price_class_1
                            )}
                            fullWidth
                            helperText={
                              touched.price_class_1 && errors.price_class_1
                            }
                            label="Enter Price Class 1"
                            margin="normal"
                            name="price_class_1"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                            value={values.price_class_1}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(
                              touched.price_class_2 && errors.price_class_2
                            )}
                            fullWidth
                            helperText={
                              touched.price_class_2 && errors.price_class_2
                            }
                            label="Enter Price Class 2"
                            margin="normal"
                            name="price_class_2"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  €
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                            value={values.price_class_2}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label="Enter Description"
                            margin="normal"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />

                          <TextField
                            error={Boolean(
                              touched.service_description_pt &&
                                errors.service_description_pt
                            )}
                            fullWidth
                            helperText={
                              touched.service_description_pt &&
                              errors.service_description_pt
                            }
                            label="Enter Service Info PT"
                            margin="normal"
                            name="service_description_pt"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.service_description_pt}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(
                              touched.service_description_en &&
                                errors.service_description_en
                            )}
                            fullWidth
                            helperText={
                              touched.service_description_en &&
                              errors.service_description_en
                            }
                            label="Enter Service Info EN"
                            margin="normal"
                            name="service_description_en"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.service_description_en}
                            variant="outlined"
                          />

                          <TextField
                            error={Boolean(
                              touched.service_order && errors.service_order
                            )}
                            fullWidth
                            helperText={
                              touched.service_order && errors.service_order
                            }
                            label="Service Order to Show"
                            margin="normal"
                            name="service_order"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="number"
                            value={values.service_order}
                            variant="outlined"
                          />
                          <Field
                            name="image"
                            component={CustomImageInput}
                            title="Select a image"
                            handleBase64={handleBase64}
                            setFieldValue={setFieldValue}
                            errorMessage={
                              errors.image ? errors.image : undefined
                            }
                            touched={touched.image}
                            style={{ display: 'flex' }}
                            onBlur={handleBlur}
                          />
                          <Field
                            name="image_selected"
                            component={CustomImageInput}
                            title="Select a image when selected"
                            handleBase64={handleSelectedBase64}
                            setFieldValue={setFieldValue}
                            errorMessage={
                              errors.image_selected
                                ? errors.image_selected
                                : undefined
                            }
                            touched={touched.image_selected}
                            style={{ display: 'flex' }}
                            onBlur={handleBlur}
                          />
                          <Box paddingY={3}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  fullWidth
                                  type="submit"
                                  variant="contained"
                                >
                                  ADD
                                </Button>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Button
                                  color="primary"
                                  disabled={isSubmitting}
                                  size="large"
                                  fullWidth
                                  onClick={goBack}
                                  variant="contained"
                                >
                                  GO BACK
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddService;
