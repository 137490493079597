import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
});

const headCells = [
  { id: 'no', numeric: false, disablePadding: false, label: 'No' },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
    sortable: true,
  },
  {
    id: 'service_name',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Payment Status',
    sortable: true,
  },
  {
    id: 'pay_method',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
    sortable: true,
  },
  {
    id: 'final_price',
    numeric: false,
    disablePadding: false,
    label: 'Final Price',
    sortable: true,
  },
  {
    id: 'modification_date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

const ClientFailedPaymentListResult = ({
  failedPayments,
  loading,
  pagerInfo,
  handlePagerInfo,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleLimitChange = (event) => {
    handlePagerInfo({
      limit: event.target.value,
      page: 1,
      totalSize: 0,
    });
  };

  const handlePageChange = (event, newPage) => {
    handlePagerInfo({
      ...pagerInfo,
      page: newPage + 1,
    });
  };

  const goToJob = (id) => {
    navigate(`/app/jobs/edit/${id}`, { state: id });
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead classes={classes} headCells={headCells} />
            <TableBody className={classes.tableBody}>
              {loading && <CircularProgress className={classes.loader} />}
              {!loading &&
                failedPayments &&
                failedPayments.map((failedPayment) => (
                  <TableRow>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {failedPayment.id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {failedPayment.firstname} {failedPayment.lastname}
                    </TableCell>
                    <TableCell>{failedPayment.service_name} Wash</TableCell>
                    <TableCell>{failedPayment.status}</TableCell>
                    <TableCell>{failedPayment.pay_method}</TableCell>
                    <TableCell>{failedPayment.final_price}</TableCell>
                    <TableCell>{failedPayment.modification_date}</TableCell>
                    <TableCell>
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => goToJob(failedPayment.order_id)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {!loading && !failedPayments && (
                <Typography className={classes.loader}>No Data</Typography>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={pagerInfo ? pagerInfo.totalSize : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={pagerInfo ? pagerInfo.page - 1 : 0}
        rowsPerPage={pagerInfo ? pagerInfo.limit : 0}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ClientFailedPaymentListResult.propTypes = {
  failedPayments: PropTypes.array,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
};

export default ClientFailedPaymentListResult;
