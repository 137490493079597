import jwtDecode from 'jwt-decode'; // Correct camelCase import
import { useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import routes from 'src/routes';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { MAIN_TOKEN_KEY } from './constants'; // Moved relative import after external library imports
import { get, remove } from './utils/storage';

const App = () => {
  const routing = useRoutes(routes);
  const navigate = useNavigate();

  useEffect(() => {
    const token = get(MAIN_TOKEN_KEY);

    if (!token) {
      navigate('/login');
    } else {
      try {
        const decodedToken = jwtDecode(token); // Renamed to camelCase
        const currentTime = Date.now() / 1000; // in seconds

        if (decodedToken.exp < currentTime) {
          remove(MAIN_TOKEN_KEY)
          navigate('/login');
        } else {
          navigate('app/admins');
        }
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/login');
      }
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      {routing}
    </>
  );
};

export default App;
