import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  FormHelperText,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { api } from 'src/service';

const ExtraPaymentAddForm = (props) => {
  const params = useParams();
  const { washer, setExtraPayments } = props;

  return (
    <Container maxWidth={false} style={{ paddingLeft: '0px' }}>
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    description: '',
                    amount: '',
                  }}
                  validationSchema={Yup.object().shape({
                    description: Yup.string()
                      .max(255)
                      .required('Description is required'),
                    amount: Yup.number().required('Amount is required'),
                  })}
                  onSubmit={async (values) => {
                    try {
                      const payload = {
                        washer_id: params.washerId,
                        description: values.description,
                        amount: values.amount,
                      };
                      await api.post(`/extra_payment`, payload).then((res) => {
                        setExtraPayments(res.data.data);
                      });
                    } catch (error) {
                      console.error(error.message);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            label="Washer first name"
                            margin="normal"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.firstname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            label="Washer last name"
                            margin="normal"
                            name="lastname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.lastname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Washer email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={washer.email}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(
                              touched.bank_iban && errors.bank_iban
                            )}
                            fullWidth
                            helperText={touched.bank_iban && errors.bank_iban}
                            label="Washer IBAN"
                            margin="normal"
                            name="bank_iban"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.bank_iban}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        mb={2}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label="Extra Payment Description"
                            margin="normal"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <TextField
                            error={Boolean(touched.amount && errors.amount)}
                            fullWidth
                            helperText={touched.amount && errors.amount}
                            type="number"
                            label="Extra Payment Amount"
                            margin="normal"
                            name="amount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <Button
                            color="primary"
                            size="large"
                            fullWidth
                            variant="contained"
                            type="submit"
                          >
                            Add Payment
                          </Button>
                        </Grid>
                      </Grid>

                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

ExtraPaymentAddForm.propTypes = {
  washer: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  bank_iban: PropTypes.string,
  setExtraPayments: PropTypes.func,
};

export default ExtraPaymentAddForm;
