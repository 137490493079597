import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { api } from 'src/service';
import { Helmet } from 'react-helmet';
import SettlementTransferAddForm from 'src/components/settlement/SettlementTransferAddForm';
import SettlementTransferTable from 'src/components/settlement/SettlementTransferTable';

const SettlementAddTransfer = () => {
  const [loading, setLoading] = useState(false);
  const [washer, setWasher] = useState(null);
  const [addForm, setAddForm] = useState(true);
  const [payments, setPayments] = useState([]);
  const [pagerInfo, setPagerInfo] = useState({
    limit: 5,
    page: 1,
    totalSize: 0,
  });
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await api.get(`/paymentwasher/${params.washerId}`).then((res) => {
          setPayments(res.data.data);
          setPagerInfo({
            limit: 5,
            page: 1,
            totalSize: res.data.data.length,
          });
        });

        await api.get(`/washer_info/${params.washerId}`).then((res) => {
          setWasher(res.data.data[0]);
        });
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Washer - Add Transfer</title>
      </Helmet>

      <Container maxWidth={false}>
        <Box paddingTop={3}>
          <Card>
            <CardHeader title="Add Transfer" />
          </Card>
        </Box>
        <Box paddingTop={3}>
          {loading && <CircularProgress />}
          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                {washer && (
                  <SettlementTransferAddForm
                    washer={washer}
                    setPayments={setPayments}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <SettlementTransferTable
                  payments={payments}
                  setPayments={setPayments}
                  pagerInfo={pagerInfo}
                />
              </Grid>
              {!addForm && (
                <Grid item xs={12} md={2} sm={4} mt={4}>
                  <Button
                    color="primary"
                    size="large"
                    fullWidth
                    variant="contained"
                    onClick={() => setAddForm(true)}
                  >
                    Add Payment
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default SettlementAddTransfer;
