import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

const MakesToolBar = (props) => {
  const navigate = useNavigate();

  const goToAdd = () => {
    navigate('/app/makes/add');
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button color="primary" variant="contained" onClick={goToAdd}>
          ADD MAKE
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <Typography>Makes</Typography>
              {/* <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search service"
                variant="outlined"
              /> */}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MakesToolBar;
