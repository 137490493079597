import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  FormHelperText,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { api } from 'src/service';

const EXTRA_PAYMENT_STATUS = [
  {
    value: 'paid',
    label: 'Paid',
  },
  {
    value: 'unpaid',
    label: 'Unpaid',
  },
];

const ExtraPaymentAddForm = (props) => {
  const params = useParams();
  const { washer, selectedPayment, setExtraPayments } = props;

  return (
    <Container maxWidth={false} style={{ paddingLeft: '0px' }}>
      <Box>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    description: selectedPayment.description,
                    amount: selectedPayment.amount,
                    status: selectedPayment.status,
                  }}
                  validationSchema={Yup.object().shape({
                    description: Yup.string()
                      .max(255)
                      .required('Description is required'),
                    amount: Yup.number().required('Amount is required'),
                  })}
                  onSubmit={async (values) => {
                    try {
                      const payload = {
                        description: values.description,
                        amount: values.amount,
                        washer_id: params.washerId,
                        status: values.status,
                      };
                      await api
                        .patch(
                          `/extra_payment/${selectedPayment.extra_payment_id}`,
                          payload
                        )
                        .then((res) => {
                          setExtraPayments(res.data.data);
                        });
                    } catch (error) {
                      console.error(error.message);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    // isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(
                              touched.firstname && errors.firstname
                            )}
                            fullWidth
                            helperText={touched.firstname && errors.firstname}
                            label="Washer first name"
                            margin="normal"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.firstname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            error={Boolean(touched.lastname && errors.lastname)}
                            fullWidth
                            helperText={touched.lastname && errors.lastname}
                            label="Washer last name"
                            margin="normal"
                            name="lastname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.lastname}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Washer email"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={washer.email}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={6} pt={0}>
                          <TextField
                            error={Boolean(
                              touched.bank_iban && errors.bank_iban
                            )}
                            fullWidth
                            helperText={touched.bank_iban && errors.bank_iban}
                            label="Washer IBAN"
                            margin="normal"
                            name="bank_iban"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={washer.bank_iban}
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={2}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label="Extra Payment Description"
                            margin="normal"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <TextField
                            error={Boolean(touched.amount && errors.amount)}
                            fullWidth
                            helperText={touched.amount && errors.amount}
                            type="number"
                            label="Extra Payment Amount"
                            margin="normal"
                            name="amount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amount}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <TextField
                            fullWidth
                            label="Status"
                            name="status"
                            margin="normal"
                            onChange={handleChange}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={values.status}
                            variant="outlined"
                          >
                            {EXTRA_PAYMENT_STATUS.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid
                        mb={4}
                        container
                        spacing={2}
                        justifyContent="flex-start"
                      >
                        <Grid item xs={12} md={12} sm={12}>
                          <Button
                            color="primary"
                            size="large"
                            fullWidth
                            variant="contained"
                            type="submit"
                          >
                            Update Payment
                          </Button>
                        </Grid>
                      </Grid>

                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                      {/* <Box paddingY={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={6} md={6}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              size="large"
                              fullWidth
                              onClick={goBack}
                              variant="contained"
                            >
                              GO BACK
                            </Button>
                          </Grid>
                        </Grid>
                      </Box> */}
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

ExtraPaymentAddForm.propTypes = {
  washer: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  bank_iban: PropTypes.string,
  setExtraPayments: PropTypes.func,
  selectedPayment: PropTypes.func,
};

export default ExtraPaymentAddForm;
