import { useState } from 'react';
import { api } from 'src/service';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CreateIcon from '@material-ui/icons/Create';
import EnhancedTableHead from '../EnhancedTableHead';

const useStyles = makeStyles({
  tableCell: {
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute !important',
    top: '20px',
    left: '45%',
  },
  tableBody: {
    position: 'relative',
    height: '60px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const headCells = [
  {
    id: 'extra_payment_id',
    numeric: false,
    disablePadding: false,
    label: 'Id',
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Washer Name',
    sortable: true,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: true,
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    sortable: true,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    sortable: true,
  },
  {
    id: 'creation_date',
    numeric: false,
    disablePadding: false,
    label: 'Creation Date',
    sortable: true,
  },
  {
    id: 'modification date',
    numeric: false,
    disablePadding: false,
    label: 'Modification Date',
    sortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ExtraPaymentsTable = ({
  payments,
  setExtraPayments,
  setAddForm,
  setSelectedPayment,
  handleRow,
  loading,
  pagerInfo,
  handlePagerInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const applyPagination = (Payments, Page, Limit) =>
    Payments.slice(Page * Limit, Page * Limit + Limit);

  const paginatedPayments = applyPagination(payments, page, limit);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const deteleExtraPayment = async (id) => {
    await api.delete(`/extra_payment/${id}`).then(() => {
      const data = payments.filter((i) => i.extra_payment_id !== id);
      setExtraPayments(data);
    });
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                headCells={headCells}
                onRequestSort={handleRequestSort}
              />
              <TableBody className={classes.tableBody}>
                {loading && <CircularProgress className={classes.loader} />}
                {!loading &&
                  paginatedPayments &&
                  stableSort(
                    paginatedPayments,
                    getComparator(order, orderBy)
                  ).map((payment) => (
                    <TableRow hover key={payment.extra_payment_id}>
                      <TableCell>{payment.extra_payment_id}</TableCell>
                      <TableCell>
                        {`${payment.firstname} ${payment.lastname}`}
                      </TableCell>
                      <TableCell>{payment.description}</TableCell>
                      <TableCell>{payment.amount}</TableCell>
                      <TableCell>{payment.status}</TableCell>
                      <TableCell>{payment.creation_date}</TableCell>
                      <TableCell>{payment.modification_date}</TableCell>
                      <TableCell>
                        <CreateIcon
                          style={{ cursor: 'pointer', marginRight: '15px' }}
                          onClick={() => {
                            setAddForm(false);
                            setSelectedPayment(payment);
                          }}
                        />
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            deteleExtraPayment(payment.extra_payment_id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {!loading && !paginatedPayments.length && (
                  <p className={classes.loader}>
                    <Typography>No Data</Typography>
                  </p>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={payments.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
    </>
  );
};

ExtraPaymentsTable.propTypes = {
  payments: PropTypes.array,
  handleRow: PropTypes.func,
  loading: PropTypes.bool,
  pagerInfo: PropTypes.object,
  handlePagerInfo: PropTypes.func,
  setSelectedPayment: PropTypes.func,
  setAddForm: PropTypes.func,
  setExtraPayments: PropTypes.func,
};

export default ExtraPaymentsTable;
