import { SET_VEHICLES_LIST, SET_VEHICLES_INFO } from './constant';
import { api } from '../../service';

/**
 * Get vehicles list.
 *
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const getVehicleTypesList = () => async (dispatch) => {
  try {
    const { data } = await api.get('/backoffice/vehicle');

    if (data) {
      // Dispatch Service List Info.
      dispatch({ type: SET_VEHICLES_LIST, payload: data.data });
    }
    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Get vehicle by ID.
 *
 * @param {string} vehicleID
 * @returns
 */
export const getVehicleTypeByID = (vehicleID) => async (dispatch) => {
  try {
    const { data } = await api.get(`/vehicle/${vehicleID}`);
    if (data) {
      dispatch({ type: SET_VEHICLES_INFO, payload: data.data });
    }

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Update vehicle by ID.
 *
 * @param {object} values
 * @param {string} vehicleID
 * @returns
 */
export const updateVehicleTypeByID = (values, vehicleID) => async () => {
  try {
    const { data } = await api.patch(`/vehicle/${vehicleID}`, values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Add vehicle.
 *
 * @param {object} values
 * @returns
 */
export const createVehicleType = (values) => async () => {
  try {
    const { data } = await api.post('/vehicle', values);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};

/**
 * Remove vehicle by ID.
 *
 * @param {string} vehicleID
 * @returns
 */
export const removeVehicleType = (vehicleID) => async () => {
  try {
    const { data } = await api.delete(`/vehicle/${vehicleID}`);

    return data.data;
  } catch (error) {
    const message = error.response ? error.response.data.moreInformation.error : { message: error.message };
    throw new Error(message);
  }
};
